import React from 'react';
import NumberFormat from 'react-number-format';

export function NumberFormatToken(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={2}
			fixedDecimalScale
			prefix={'Tk '}
			allowNegative={false}
		/>
	);
}

export function NumberFormatEuro(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={2}
			fixedDecimalScale
			suffix={' €'}
			allowNegative={false}
		/>
	);
}

export function NumberFormatPercent(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			isAllowed={({ value }) => value >= 0 && value <= 100}
			thousandSeparator
			isNumericString
			decimalScale={2}
			fixedDecimalScale
			suffix={'%'}
			allowNegative={false}
		/>
	);
}

export function NumberFormatInteger(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={0}
			fixedDecimalScale
			allowNegative={false}
		/>
	);
}

export function NumberFormatFloat(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={2}
			fixedDecimalScale
			allowNegative={false}
		/>
	);
}

export function NumberFormatMinutes(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				});
			}}
			thousandSeparator
			isNumericString
			decimalScale={0}
			fixedDecimalScale
			allowNegative={false}
			suffix=' min'
		/>
	);
}

// function NumberFormatCard(props) {
// 	const { inputRef, onChange, ...other } = props;

// 	return (
// 		<NumberFormat
// 			{...other}
// 			getInputRef={inputRef}
// 			onValueChange={(values) => {
// 				onChange({
// 					target: {
// 						name: props.name,
// 						value: values.value,
// 					},
// 				});
// 			}}
// 			format='#### #### #### ####'
// 		/>
// 	);
// }

// function NumberFormatCvv(props) {
// 	const { inputRef, onChange, ...other } = props;

// 	return (
// 		<NumberFormat
// 			{...other}
// 			getInputRef={inputRef}
// 			onValueChange={(values) => {
// 				onChange({
// 					target: {
// 						name: props.name,
// 						value: values.value,
// 					},
// 				});
// 			}}
// 			format='###'
// 		/>
// 	);
// }
