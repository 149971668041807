import { Grid, IconButton, useMediaQuery, } from "@mui/material";
import { makeStyles } from '@mui/styles';
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import { DashboardContext } from "../../../contexts/dashboard.context.jsx";
import AlertCollapse from "../../customComponents/alert.collapse";
import DraggableContainer from "../../customComponents/draggable.container";
import OwnerRoomButtonBar from "./owner.room.button.bar";
import OwnerRoomTable from "./owner.room.table";
import DashboardNotification from "../dashboard/owner.dashboard.notification.jsx";
import DeliveryNotification from "../delivery/deliveryNotification.jsx";
import socketIOClient from "socket.io-client";
import ComponentLoader from "../../customComponents/component.loader.jsx";
import Notification from "../../customComponents/notification.jsx";

const canvasSize = 2000;

const useStyles = makeStyles((theme) => ({
  canvas: {
    marginLeft: "1%",
    width: "98%",
    height: "85vh",
    border: `2px dashed ${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius,
    "&::-webkit-scrollbar": {
      width: "10px",
      height: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#43a047",
      outline: "1px solid slategrey",
    },
    //firefox
    "scrollbar-color": "#43a047",
  },

  container: {
    display: "flex",
    flexDirection: "row",
    "&:after": {
      content: '" "',
      display: "block",
      flex: 1,
    },
  },
  containerChildLeft: {
    flex: 1,
    textAlign: "left",
  },
  addButton: {
    /* marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1), */
    top: '1px',
    left: '5px',
    backgroundColor: '#43a047 !important',
    position: 'absolute !important'
  },
}));

export default function OwnerRoom() {
  const classes = useStyles();
  const canvasRef = useRef();
  const [tables, setTables] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [isLocked, setIsLocked] = useState(true);
  const [isClickedOut, setIsClickedOut] = React.useState(false);
  const isMobile = useMediaQuery("(max-width:576px)");
  const { user } = useContext(UserContext);
  const { isLoading: isLoadingDash, notification, setNotification } = useContext(DashboardContext);
  const [socket, setSocket] = useState(null);
  const [connected, setConnected] = useState(false);

  const getSocketId = () => (socket ? socket.id : null);

  localStorage.setItem('filterRes', 'tutto')

  useEffect(() => {
    if (user && user.id) {
      const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
        secure: true,
        transports: ["websocket"],
        query: { ownerId: user.id },
      });
      setSocket(s);
    }
  }, [user]);

  //manage real time events
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        setConnected(true);
      });
      socket.on("disconnect", () => {
        setConnected(false);
      });
      socket.on("switchTableStatus", switchTableStatus);
      socket.on("updateRoom", updateRoom);
      return () => {
        socket.off("switchTableStatus");
        socket.off("updateRoom");
        socket.off("connect");
        socket.off("disconnect");
      };
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);



  useEffect(() => {
    if (!tables && currentRoom) {
      let subscribed = true;
      setError(false);
      setIsLoading(true);
      axios
        .post("/api/rooms/owner/tables/get", { id: currentRoom, socketId: getSocketId() })
        .then(({ data }) => {
          if (subscribed) {
            if (!data || !data.success) {
              //error
              setTables([]);
              setIsLoading(false);
              setError(true);
            } else {
              //success
              setTables(data.tablesList);
              setIsLoading(false);
            }
          }
        });

      return () => (subscribed = false);
    }
    setIsLoading(false);// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoom, tables]);


  const updateRoom = () => {
    let subscribed = true;
    setError(false);
    setIsLoading(true);
    axios
      .post("/api/rooms/owner/tables/get", { id: currentRoom, socketId: getSocketId() })
      .then(({ data }) => {
        if (subscribed) {
          if (!data || !data.success) {
            //error
            setTables([]);
            setIsLoading(false);
            setError(true);
          } else {
            //success
            setTables(data.tablesList);
            setIsLoading(false);
          }
        }
      });
    setIsLoading(false)
    return () => (subscribed = false);
  };


  const createTable = () => {
    setError(false);
    let newTable = {
      x: 10,
      y: 70,
      roomId: currentRoom,
    };
    axios.post("/api/tables/owner/add", newTable).then(({ data }) => {
      if (!data || !data.success) {
        setError(true);
      } else {
        newTable.id = data.id;
        newTable.businessId = data.businessId
        setTables((t) => [...t, newTable]);
      }
    });
  };

  const setCoords = (tableId, x, y, businessId) => {
    let t = [...tables];
    let i = t.findIndex((tb) => tb.id === tableId);
    if (i !== -1) {
      t[i] = { ...t[i], x, y };
      setTables(t);
    }

    updateCoords(tableId, x, y, businessId);
  };

  const updateCoords = (tableId, x, y, businessId) => {
    axios.post("/api/tables/owner/setCoords", {
      tableId,
      x,
      y,
      businessId
    });
  };

  const switchTableStatus = (tableId, businessId) => {
    let t = [...tables];
    let i = t.findIndex((tb) => tb.id === tableId && tb.businessId === businessId);
    if (i !== -1) {
      axios
        .post("/api/tables/owner/setStatus", {
          id: tableId,
          status: !t[i].status,
          businessId,
          socketId: getSocketId(),
        })
        .then(({ data }) => {
          if (!data || !data.success) {
            return;
          }
          t[i].status = !t[i].status;
          setTables(t);
        });
    }
  };

  const deleteTable = (tableId, businessId) => {
    let t = [...tables];
    let i = t.findIndex((tb) => tb.id === tableId && tb.businessId === businessId);
    if (i !== -1) {
      axios
        .post("/api/tables/owner/delete", { id: tableId, businessId })
        .then(({ data }) => {
          if (!data || !data.success) {
            setError(true);
          } else {
            setTables(t.filter(({ id }) => id !== tableId));
          }
        });
    }
  };


  return (
    <div id="onwer_room">
      <Notification
        setStatus={setNotification}
        status={notification}
        bottom={true}
      />
      <ComponentLoader isLoading={isLoadingDash}>
        <OwnerRoomButtonBar
          rooms={rooms}
          setRooms={setRooms}
          setIsLoading={setIsLoading}
          setCurrentRoom={setCurrentRoom}
          currentRoom={currentRoom}
          setTables={setTables}
          isLocked={isLocked}
          setIsLocked={setIsLocked}
        />

        <AlertCollapse
          text={"Si è verificato un errore"}
          show={!isLoading && error}
        />




        {!isMobile ? (
          <DraggableContainer
            className={classes.canvas}
            size={canvasSize}
            ref={canvasRef}
            onClick={() => setIsClickedOut(true)}
          >
            {tables &&
              tables.map((table, i) => (
                <OwnerRoomTable
                  key={table.id}
                  canvasRef={canvasRef}
                  table={table}
                  multiTable={tables}
                  setCoords={setCoords}
                  canvasSize={canvasSize}
                  isLocked={isLocked}
                  setIsLocked={setIsLocked}
                  switchTableStatus={switchTableStatus}
                  deleteTable={deleteTable}
                />
              ))}
            {!isLocked && (
              <IconButton onClick={createTable} className={classes.addButton}>
                <AddIcon />
              </IconButton>
            )}
          </DraggableContainer>

        ) : (

          <Grid
            container spacing={1}
            onClick={() => setIsClickedOut(true)}
            style={{ marginBottom: '150px' }}
          >
            {tables &&
              tables.map((table, i) => (
                <Grid key={table.id} item xs>
                  <OwnerRoomTable
                    key={table.id}
                    table={table}
                    multiTable={tables}
                    isLocked={isLocked}
                    setIsLocked={setIsLocked}
                    switchTableStatus={switchTableStatus}
                    deleteTable={deleteTable}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {(user.version === 1 || user.version === 3) &&
          <DashboardNotification
            setIsClickedOut={setIsClickedOut}
            isClickedOut={isClickedOut}
          />
        }

        {(user.version === 2 || user.version === 3) &&
          <DeliveryNotification />
        }


      </ComponentLoader>
    </div>
  );
}
