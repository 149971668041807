import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useState } from "react";

export default function ConfirmDialog({
  text,
  handleClose: onClose,
  open,
  successTimeout,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccess = () => {
    setIsLoading(true);
    if (successTimeout) {
      setTimeout(() => {
        handleClose(true);
        setIsLoading(false);
      }, successTimeout);
    } else {
      handleClose(true);
      setIsLoading(false);
    }

  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose(event);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>{text.text}</DialogTitle>
        <DialogContent style={{ backgroundColor: '#383838' }}>
          <DialogContentText>{text.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleClose(false)}
            color="secondary"
          >
            Annulla
          </Button>
          <Button onClick={handleSuccess} color="primary" autoFocus>
            {isLoading ? <CircularProgress size={24} /> : "Conferma"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
