import React, { useState } from 'react'
import { calculateTotalWithFees } from '../../../functions'
import InfoIcon from '@mui/icons-material/Info';
import { Typography, Button, Grid, RadioGroup, FormControlLabel, Radio, Dialog, DialogContent } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function OrderPayment({
    formData,
    totalPrice,
    paymentOption,
    isOwner,
    getSumTotal,
    handlePayOptionChange,
    goBack,
    handleOrder,
    handlePay,
    loading,
}) {
    const [openInfoDialog, setOpenInfoDialog] = useState(false);

    const handleInfoDialog = () => {
        setOpenInfoDialog(!openInfoDialog);
    };


    return (
        <>
            <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Subtotale: {totalPrice.toFixed(2).replace('.', ',')} €</Typography>


            {formData.deliveryOption.toLowerCase() === 'consegna' && <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Spese di consegna: {Number(formData.costoConsegna).toFixed(2).replace('.', ',')} €</Typography>}

            {paymentOption.toLowerCase() === 'carta' && isOwner === false && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5.6px' }}>
                    <Typography
                        style={{ letterSpacing: '1px', }}
                        variant="subtitle1"
                    >
                        Costi di servizio: {calculateTotalWithFees(getSumTotal(), true, paymentOption).toString().replace('.', ',')} €

                    </Typography>
                    <Button onClick={handleInfoDialog}>
                        <InfoIcon />
                    </Button>
                </div>
            )}

            <Typography style={{ letterSpacing: '1px' }} variant="h5" gutterBottom>Totale: {isOwner === false ? calculateTotalWithFees(getSumTotal(), false, paymentOption).toString().replace('.', ',') : getSumTotal().toFixed(2).replace('.', ',')} €</Typography>

            {isOwner === false && (
                <>
                    <Typography variant="h6" style={{ margin: '40px 0', textAlign: 'center', fontWeight: 'bold', letterSpacing: 1, border: '1px solid green', padding: 5, borderRadius: '125px', backgroundColor: 'green' }}>Scegli il metodo di pagamento</Typography>
                    <Grid container>
                        <Grid item xs>
                            <RadioGroup value={paymentOption} onChange={handlePayOptionChange}>
                                <FormControlLabel value="carta" control={<Radio />} label={`Paga ora - Salta la fila e risparmia tempo!`} />
                                <FormControlLabel value="cassa" control={<Radio />} label={formData.deliveryOption.toLowerCase() === 'ritiro' ? 'Paga in cassa' : 'Paga alla consegna'} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </>
            )}

            <div style={{ width: '100%', flexWrap: 'wrap', gap: '5px', marginTop: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    size='small'
                    onClick={() => goBack()}
                    startIcon={<ArrowBackIcon />}
                >
                    Indietro
                </Button>
                <Button
                    size='small'
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={(isOwner || paymentOption.toLowerCase() === 'cassa') ? handleOrder : handlePay}
                    disabled={loading}
                >
                    {(isOwner || paymentOption.toLowerCase() === 'cassa') ? 'Invia ordine' : 'Vai al pagamento'}
                </Button>
            </div>

            <Dialog open={openInfoDialog} onClose={handleInfoDialog}>
                <DialogContent>
                    <Typography variant="body1">
                        I costi di servizio ci permettono di offrirti la migliore esperienza, garantendo un servizio affidabile e sostenendo lo sviluppo della nostra app.
                        <br /><br />
                        Le commissioni sono calcolate in percentuale e questa si abbassa con l'aumento dell'importo totale
                    </Typography>
                    <Button style={{ marginTop: '25px', width: '100%' }} variant="outlined" onClick={handleInfoDialog} color="primary">Chiudi</Button>
                </DialogContent>
            </Dialog>

        </>

    )
}

export default OrderPayment