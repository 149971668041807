import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import { UserContext } from './user.context';

const useOrder = () => {
	const { user } = useContext(UserContext);
	const [socket, setSocket] = useState(null);
	const [connected, setConnected] = useState(false);
	let { ownerId } = useParams();
	const [enabled, setEnabled] = useState(null);

	ownerId = decodeURIComponent(ownerId);

	// Connessione al socket
	useEffect(() => {
		if (user && user.id) {
			const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
				secure: true,
				transports: ['websocket'],
				query: { ownerId, type: 'user' },
			});
			setSocket(s);

			// Pulizia del socket alla disconnessione
			return () => {
				s.disconnect();
			};
		}
	}, [user, ownerId]);

	// Chiamata API per ottenere lo stato `enabled`
	useEffect(() => {
		const fetchOrderEnabled = async () => {
			try {
				const response = await axios.post('/api/user/orderEnabled/get', { ownerId });
				setEnabled(response.data.orderEnabled);
			} catch (error) {
				console.error("Errore durante la chiamata API per ottenere lo stato 'enabled':", error);
			}
		};

		if (ownerId) {
			fetchOrderEnabled();
		}
	}, [ownerId]);

	// Gestione degli eventi in tempo reale
	useEffect(() => {
		if (socket) {
			socket.on('connect', () => {
				setConnected(true);
			});
			socket.on('disconnect', () => {
				setConnected(false);
			});
			socket.on('disableOrder', disableOrder);
			socket.on('enableOrder', enableOrder);

			// Pulizia dei listener degli eventi al dismontaggio
			return () => {
				socket.off('disableOrder', disableOrder);
				socket.off('enableOrder', enableOrder);
				socket.off('connect');
				socket.off('disconnect');
			};
		}
	}, [socket]);

	const enableOrder = () => setEnabled(true);

	const disableOrder = () => setEnabled(false);

	return {
		enabled,
		connected,
	};
};

export const OrderContext = createContext();

export const OrderProvider = (props) => {
	const order = useOrder();

	return (
		<OrderContext.Provider value={order}>
			{props.children}
		</OrderContext.Provider>
	);
};
