import React from 'react'
import { Typography, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function OrderNotPayment({
    formData,
    totalPrice,
    getSumTotal,
    goBack,
    handleOrder,
    loading,
}) {
    return (
        <>
            <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Subtotale: {totalPrice.toFixed(2).replace('.', ',')} €</Typography>
            {formData.deliveryOption.toLowerCase() === 'consegna' && <Typography style={{ letterSpacing: '1px' }} variant="subtitle1" gutterBottom>Spese di consegna: {Number(formData.costoConsegna).toFixed(2).replace('.', ',')} €</Typography>}
            <Typography style={{ letterSpacing: '1px' }} variant="h5" gutterBottom>Totale: {getSumTotal().toFixed(2).replace('.', ',')} €</Typography>

            <div style={{ width: '100%', flexWrap: 'wrap', gap: '5px', marginTop: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    color="secondary"
                    size='small'
                    onClick={() => goBack()}
                    startIcon={<ArrowBackIcon />}
                >
                    Indietro
                </Button>
                <Button
                    size='small'
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleOrder}
                    disabled={loading}
                >
                    {'Invia ordine'}
                </Button>
            </div>
        </>

    )
}

export default OrderNotPayment