import { Badge, Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import React, { useContext, useEffect, useRef } from "react";
import axios from 'axios';
import { DashboardContext } from "../../../contexts/dashboard.context";
import { useHistory } from "react-router-dom";
import notificationSoundNewOrder from "../../../notification/Melodico.mp3";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed !important",
        bottom: theme.spacing(4),
        right: theme.spacing(13),
        zIndex: 1,  //per qualche motivo lo snackbar ha zindex 1400
    },
    animationDelivery: {
        position: "fixed !important",
        bottom: theme.spacing(4),
        right: theme.spacing(13),
        zIndex: 1401,
        animation: '$anDelivery 2s'
    },
    "@keyframes anDelivery": {
        "0%": { transform: "rotate(0) scale(1)" },
        "4%": { transform: "rotate(30deg) scale(1.128)" },
        "8%": { transform: "rotate(-28deg) scale(1.256)" },
        "12%": { transform: "rotate(34deg) scale(1.384)" },
        "16%": { transform: "rotate(-32deg) scale(1.384)" },
        "20%": { transform: "rotate(30deg) scale(1.256)" },
        "24%": { transform: "rotate(-28deg) scale(1.128)" },
        "28%": { transform: "rotate(26deg) scale(1)" },
        "32%": { transform: "rotate(-24deg)" },
        "36%": { transform: "rotate(22deg)" },
        "40%": { transform: "rotate(-20deg)" },
        "44%": { transform: "rotate(18deg)" },
        "48%": { transform: "rotate(-16deg)" },
        "52%": { transform: "rotate(14deg)" },
        "56%": { transform: "rotate(-12deg)" },
        "60%": { transform: "rotate(10deg)" },
        "64%": { transform: "rotate(-8deg)" },
        "68%": { transform: "rotate(6deg)" },
        "72%": { transform: "rotate(-4deg)" },
        "76%": { transform: "rotate(2deg)" },
        "80%": { transform: "rotate(-1deg)" },
        "84%": { transform: "rotate(1deg)" },
        "88%": { transform: "rotate(0)" },
        "100%": { transform: "rotate(0)" },
    }
}));

function DeliveryNotification() {
    const classes = useStyles();
    const { deliveryCount, setDeliveryCount, notifiDelivery, setNotifiDelivery } = useContext(DashboardContext);
    const history = useHistory();
    const intervalRef = useRef(null); // Using useRef to keep track of the interval ID

    useEffect(() => {
        // Fetch the initial delivery count
        axios.get('/api/delivery/getCountDelivery').then(res => {
            setDeliveryCount(res.data.result);
            setNotifiDelivery(true);
        });// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Clear the previous interval if it exists
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        // Set up an interval to play the sound every 3 minutes if deliveryCount is greater than 0
        if (deliveryCount > 0) {
            intervalRef.current = setInterval(() => {
                const newOrderSound = new Audio(notificationSoundNewOrder);
                newOrderSound.play().catch(err => {
                    console.log('Errore notifica suono ' + err
                    )
                  });
            }, 180000); // 180000 ms = 3 minutes 
        }

        // Clean up the interval on component unmount
        return () => clearInterval(intervalRef.current);
    }, [deliveryCount]); // Dependency on deliveryCount ensures interval is set based on the latest count

    const handleClick = () => {
        if (history.location.pathname !== '/owner/delivery') {
            history.push('/owner/delivery');
        }
    };

    return (
        <Fab
            className={notifiDelivery ? classes.animationDelivery : classes.fab}
            disabled={deliveryCount === 0}
            onAnimationEnd={() => setNotifiDelivery(false)}
            onClick={handleClick}
        >
            <Badge
                badgeContent={deliveryCount}
                color="secondary"
                overlap="rectangular"
            >
                <DriveEtaIcon />
            </Badge>
        </Fab>
    );
}

export default DeliveryNotification;
