export const styleVariants = {
    fontWeight: 'bold', 
    fontVariant: 'small-caps', 
    letterSpacing: 1, 
    fontSize: 18, 
    color: '#909090'
}

export const styleOptions = {
    color: '#fff', 
    fontWeight: 'bold', 
    letterSpacing: 1
}