import React, { useEffect } from 'react'
import UserCart from "./owner.room.cart"
import {
    Dialog,
    Slide,
    AppBar,
    IconButton,
    Typography,
    Toolbar,
} from "@mui/material";
import MuiDialogContent from "@mui/material/DialogContent";
import FabScroll from '../../customComponents/fab.scroll';
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from '@mui/styles';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";


const useStyles = makeStyles((theme) => ({
    dialogHeader: {
        backgroundColor: theme.palette.primary.main,
    },

    fab: {
        position: 'fixed !important',
        bottom: theme.spacing(1) + '!important',
        left: theme.spacing(1) + ' !important',
        //bottom: theme.spacing(30),
        backgroundColor: theme.palette.primary.main + ' !important',
    },
    numberOrder: {
        color: '#fff',
        backgroundColor: 'red',
        borderRadius: '50%', // Utilizzare 50% per creare un cerchio perfetto
        padding: '3px 8px', // Puoi regolare il padding in base alle tue esigenze
        // display: 'inline-block', // Per assicurarti che il cerchio si adatti correttamente al contenuto
        textAlign: 'center', // Centra il contenuto all'interno del cerchio
        //minWidth: '20px', // Larghezza minima per assicurarti che il cerchio sia visibile
    }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function RoomCart({ setOpenCartDialog, openCartDialog, handleClickOrder, cart, getAmountTotal, tableId, ownerId, deliveryId }) {
    const classes = useStyles();

    const _handleCloseCart = () => {
        setOpenCartDialog(!openCartDialog)
        handleClickOrder();
    }

    useEffect(() => { 
        getAmountTotal(cart) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart])

    return (
        <>
            <MuiDialogContent >
                <Dialog onClose={() => setOpenCartDialog(!openCartDialog)} fullScreen TransitionComponent={Transition} open={openCartDialog}>
                    <AppBar position="sticky">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setOpenCartDialog(!openCartDialog)}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6">Carrello</Typography>
                        </Toolbar>
                    </AppBar>
                    <UserCart handleClickOrder={_handleCloseCart} tableId={tableId} deliveryId={deliveryId} ownerId={ownerId} setOpenDialog={setOpenCartDialog} openDialog={openCartDialog} />
                </Dialog>
            </MuiDialogContent>

            <FabScroll
                aria-label='out-menu'
                className={classes.fab}
                onClick={() => setOpenCartDialog(!openCartDialog)}
                icon={ShoppingCartIcon}
                text={
                    <>
                        <span>Visualizza e invia </span>
                        <span className={classes.numberOrder}>{cart.length}</span>
                    </>}
                size='small'
            />
        </>
    )
}

export default RoomCart