import React, { useState, useRef } from 'react';
import { Dialog, Button, DialogTitle, Grid, Typography, RadioGroup, Radio, FormControlLabel, IconButton, Checkbox, FormGroup, FormControl, FormLabel, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
        textTransform: 'capitalize'
    },
    gridItem: {
        width: '100%',
        borderBottom: `1px solid ${theme.palette.divider}`,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        borderRight: `1px solid ${theme.palette.divider}`,
        borderLeft: `1px solid ${theme.palette.divider}`,
    },
}));


function OwnerRoomDialogVariants({ setDialogVariants, dialogVariants, addToCart }) {
    const classes = useStyles();
    const [variantsSelection, setVariantsSelection] = useState([]);
    const [variantsError, setVariantsError] = useState(false);
    const [isMultiSelection, setIsMultiSelection] = useState(Boolean(0));
    const [selectedForDelete, setSelectedForDelete] = useState(new Set());
    const errorMsg = useRef();

    const getOption = (variantId, optionId) => {
        if (dialogVariants.data.variants && dialogVariants.data.variants.length !== 0) {
            let index = dialogVariants.data.variants.findIndex(({ id }) => id === variantId);
            if (index !== -1) {
                let i = dialogVariants.data.variants[index].options.findIndex(({ id }) => id === optionId);
                if (i !== -1) {
                    return dialogVariants.data.variants[index].options[i];
                }
            }
        }
    };

    const removeSelected = (idValueTarget) => {
        let _variantSelection = [...variantsSelection];
        let isFilter = false; // Abbiamo l'id del Selected
        for (let x of _variantSelection) { // Entro nel variants
            if (x.selected) {
                for (let y of x.selected) { // Entro nel selected del variants
                    if (y.id === idValueTarget) {
                        y.id = 'to delete'
                        isFilter = true;
                        break;
                    }
                }
                if (isFilter) {
                    // eslint-disable-next-line
                    _variantSelection = _variantSelection.filter(el => {
                        if (el.selected) {
                            for (let z of el.selected) {
                                // Ritorno tutti gli elementi nel variantSelection
                                // tranne quelli che nel selected.id ha il valore 'to delete'
                                if (z.id !== 'to delete') {
                                    return el;
                                }
                            }
                        }

                    });
                    isFilter = false;
                }
            }
        }
        return _variantSelection;
    };

    /**
     * Questa funzione ti permette di trovare se nelle variants ci sono i multi (0 o 1);
     * Se trova un mix restituisce true
     * Se non trova un minx e quindi sono o tutti multi o tutti single restituisce false
     */
    function checkConsistentMulti(variants) {
        // Ottieni il valore di multi del primo elemento
        const firstMulti = variants[0].multi;
        // Verifica se tutti gli altri elementi hanno lo stesso valore di multi del primo elemento
        for (let i = 1; i < variants.length; i++) {
            if (variants[i].multi !== firstMulti) {
                return false; // Se almeno un elemento ha un valore di multi diverso, restituisci false
            }
        }
        return true; // Se tutti gli elementi hanno lo stesso valore di multi, restituisci true

    }

    const handleChangeVariant = (e, variantId) => {
        let updatedVariants = [...dialogVariants.data.variants];
        let index = updatedVariants.findIndex(({ id }) => id === variantId);
        const _checkConsistentMulti = checkConsistentMulti(updatedVariants);

        if (!_checkConsistentMulti) {

            // Se l'elemento è già presente nelle varianti selezionate, rimuovilo
            let updatedSelection = [...variantsSelection];

            // Aggiungi l'elemento selezionato solo se la checkbox è spuntata

            if (e.target.checked) {
                if (e.target.type === 'checkbox') {
                    updatedSelection.push({
                        ...updatedVariants[index],
                        id: variantId,
                        selected: [{ ...getOption(variantId, e.target.value) }]
                    });
                } else {
                    // Se è un radio, sostituisci il vecchio valore con il nuovo
                    const indexElse = updatedSelection.map(el => el.id).indexOf(variantId);
                    if (indexElse === -1) {
                        updatedSelection.push({
                            ...updatedVariants[index],
                            id: variantId,
                            selected: [{ ...getOption(variantId, e.target.value) }]
                        });
                    } else {
                        updatedSelection[indexElse] = {
                            ...updatedSelection[indexElse],
                            ...updatedVariants[index],
                            selected: [{ ...getOption(variantId, e.target.value) }]
                        }
                    }
                }
            } else {
                updatedSelection = removeSelected(e.target.value)
            }
            const removeDuplicate = new Set([...updatedSelection]);
            const transformToArray = Array.from(removeDuplicate);

            setVariantsSelection(transformToArray);

        } else {
            if (e.target.type === 'checkbox') {
                if (e.target.checked) {
                    updatedVariants[index] = {
                        ...updatedVariants[index],
                        selected: [{ ...getOption(variantId, e.target.value) }]
                    };
                    setVariantsSelection([...variantsSelection, updatedVariants[index]]);
                } else setVariantsSelection(removeSelected(e.target.value));
            } else {
                updatedVariants[index] = {
                    ...updatedVariants[index],
                    selected: [{ ...getOption(variantId, e.target.value) }]
                };
                setDialogVariants(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        variants: updatedVariants
                    }
                }));
                setVariantsSelection(updatedVariants);
            }
        }
    };


    //se sei multi (radio e checkbox) deve scattare l'errore solo sul radio, quindi va a vedere se i selected ci sono dei radio button
    function checkRadioRequired(dialogVariants, variantsSelection) {
        for (const variant of dialogVariants) {
            if (variant.multi === 0) {
                const selectedVariant = variantsSelection.find(selection => selection.id === variant.id);
                if (!selectedVariant) {
                    return true; // Opzione mancante trovata
                }
            }
        }
        return false; // Nessuna opzione mancante trovata
    }


    /**
   * Questa funzione ti permette di trovare se nelle variants sono tutte radio;
   * Se sono tutti radio restituisce un true altrimenti false
   */
    function isAllRadio(variants) {
        // Verifica se tutti gli elementi sono radio
        for (let i = 1; i < variants.length; i++) {
            if (variants[i].multi !== 0) { // 0 Radio 1 Check
                return false; // Se almeno un elemento ha un valore multi = 1 (cioe check), restituisci false
            }
        }
        return true; // Se tutti gli elementi hanno lo stesso valore = 0 (cioe radio) in multi, restituisci true

    }


    const handleConfirm = () => {

        let sonoMulti = !checkConsistentMulti(dialogVariants.data.variants)
        if (sonoMulti) {

            let radioMancante = checkRadioRequired(dialogVariants.data.variants, variantsSelection)
            if (radioMancante) {
                //console.log('1')
                //setVariantsError(true);
                //setIsMultiSelection(Boolean(0)); // Questo perché non deve visualizzare l'errore dei checkbox quando sono multi
                //errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                //return;
            }

        } else {
            if (variantsSelection.length === 0) {
                if (isAllRadio(dialogVariants.data.variants)) {
                    setVariantsError(true);
                    setIsMultiSelection(Boolean(dialogVariants.data.variants[0].multi)); // Solo se tutto il dialog ha la selezione multi.
                    errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                    return;
                }
            } else {
                for (let x of variantsSelection) {
                    if (x.multi === 0 && !x.hasOwnProperty('selected')) {
                        setVariantsError(true);
                        errorMsg.current?.scrollIntoView({ top: 0, behavior: 'smooth' })
                        return;
                    }
                }
            }
        }

        const deletedVariantsNotes = Array.from(selectedForDelete)
            .map(item => `NO ${item.name}`)
            .join(", ");


        addToCart({
            ...dialogVariants.data,
            amount: 1,
            note: deletedVariantsNotes,
            variants: variantsSelection,
        });
        setVariantsSelection([]);
        setVariantsError(false);
        setDialogVariants({ isOpen: false, data: {} })
        setSelectedForDelete(new Set())
    }

    const onCloseDialog = () => {
        setDialogVariants({ isOpen: false, data: {} });
        setVariantsSelection([]);
        setVariantsError(false);
        setSelectedForDelete(new Set())
    }


    const sortOptions = (options) => {
        // Filtra le opzioni "No grazie"
        const noThanks = options.filter((opt) => opt.label.toLowerCase().includes("no, grazie!"));
        // Filtra le altre opzioni e le ordina alfabeticamente
        const others = options.filter((opt) => !opt.label.toLowerCase().includes("no, grazie!")).sort((a, b) => a.label.localeCompare(b.label));
        // Combina "No grazie" e le altre opzioni
        return [...noThanks, ...others];
    };


    const handleDeleteVariant = (variantId, variantName) => {

        // Se la variante è già selezionata (verifica con id)
        if (Array.from(selectedForDelete).some(item => item.id === variantId)) {

            setSelectedForDelete(prevState => {
                const newState = new Set(prevState);

                // Rimuovi l'oggetto con l'id corrispondente
                newState.forEach(item => {
                    if (item.id === variantId) {
                        newState.delete(item); // Rimuove l'oggetto completo
                    }
                });

                return newState;
            });
        } else {
            // Aggiungi la variante alla lista per la cancellazione
            setVariantsSelection(removeSelected(variantId))
            setSelectedForDelete(prevState => new Set(prevState).add({ id: variantId, name: variantName }));
        }

    };


    return (
        <Dialog stlye={{ position: 'relative' }} fullWidth={true} fullScreen open={dialogVariants.isOpen} id="error">

            <DialogTitle ref={errorMsg} className={classes.dialogTitle}>
                <IconButton
                    style={{ position: 'absolute', left: '20px', top: '10px' }}
                    edge="start"
                    color="inherit"
                    onClick={() => onCloseDialog()}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
                {dialogVariants.data.name}
            </DialogTitle>

            {(variantsError && isMultiSelection) && (
                <FormLabel component="legend" style={{ textAlign: 'center', margin: '10px 0' }} >
                    <Typography variant="body1" color="secondary">
                        Selezionare almeno una categoria
                    </Typography>
                </FormLabel>
            )}


            <Grid container style={{ marginBottom: '70px' }}>
                {dialogVariants.data.variants?.map((el, index) => (
                    <Grid key={index} item xs className={classes.gridItem}>
                        <Typography style={{ fontVariant: 'small-caps', fontSize: '24px', padding: '10px 0 15px 0' }} align='center' variant="h5">{el.name}</Typography>

                        <FormControl style={{ width: '100%' }} onChange={(e) => handleChangeVariant(e, el.id)}>

                            {el.multi ? (
                                <FormGroup
                                    aria-label="gender"
                                    name="gender1"
                                    style={{ paddingLeft: '15px', width: '100%' }}
                                    value={el.id}
                                >

                                    {sortOptions(el.options).map(({ id, label, price }, index) => (
                                        <div key={id} style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '10px' }}>
                                            {/* Contenuto principale */}
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <FormControlLabel
                                                    value={id}
                                                    control={<Checkbox style={{ display: Array.from(selectedForDelete).some(item => item.id === id) ? 'none' : 'inline-block' }} />} // Nasconde la checkbox se la variante è selezionata per la cancellazione
                                                    checked={variantsSelection.some(v => v.id === el.id && v.selected.some(option => option.id === id))} // Controlla se questa opzione è selezionata
                                                    label={
                                                        <>
                                                            <Typography variant="inherit"
                                                                style={{
                                                                    textTransform: 'capitalize',
                                                                    textDecoration: Array.from(selectedForDelete).some(item => item.id === id) ? 'line-through' : 'none',
                                                                }}>
                                                                {label}{" "}
                                                                {price > 0 && (
                                                                    <Typography variant="overline" color="textSecondary">
                                                                        +{price.toFixed(2)}€
                                                                    </Typography>
                                                                )}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDeleteVariant(id, label)} // Aggiungi la logica per rimuovere la variante
                                                >
                                                    {Array.from(selectedForDelete).some(item => item.id === id) ? (
                                                        <ReplayIcon fontSize="small" color="primary" />
                                                    ) : (
                                                        <DeleteIcon fontSize="small" color="secondary" />
                                                    )}
                                                </IconButton>
                                            </div>

                                            {/* Divider separato */}
                                            {el.options.length - 1 !== index && (
                                                <Divider style={{ width: '90%', margin: '5px 0', alignSelf: 'center' }} />
                                            )}
                                        </div>
                                    ))}
                                </FormGroup>
                            ) : (
                                <>
                                    <FormLabel component="legend">
                                        <Typography color="textPrimary" variant="h5">
                                            {variantsError && (
                                                <Typography variant="body1" color="secondary" textAlign={'center'}>
                                                    (scelta obbligatoria)
                                                </Typography>
                                            )}
                                        </Typography>
                                    </FormLabel>

                                    <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        style={{ paddingLeft: '15px', width: '100%' }}
                                    >
                                        {sortOptions(el.options).map(({ id, label, price }, index) => (
                                            <div key={id} style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '10px' }}>
                                                {/* Contenuto principale */}
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <FormControlLabel
                                                        value={id}
                                                        control={<Radio style={{ display: Array.from(selectedForDelete).some(item => item.id === id) ? 'none' : 'inline-block' }} />} // Nasconde il radio se la variante è selezionata per la cancellazione
                                                        checked={variantsSelection.some(v => v.id === el.id && v.selected?.some(option => option.id === id))} // Controlla se questa opzione è selezionata
                                                        label={
                                                            <>
                                                                <Typography
                                                                    variant="inherit"
                                                                    style={{
                                                                        textTransform: 'capitalize',
                                                                        textDecoration: Array.from(selectedForDelete).some(item => item.id === id) ? 'line-through' : 'none',  // Aggiungi la linea attraverso se la variante è selezionata
                                                                    }}
                                                                >
                                                                    {label}{" "}
                                                                    {price > 0 && (
                                                                        <Typography variant="overline" color="textSecondary">
                                                                            +{price.toFixed(2)}€
                                                                        </Typography>
                                                                    )}
                                                                </Typography>
                                                            </>
                                                        }
                                                    />
                                                    <IconButton
                                                        size="small"

                                                        onClick={() => handleDeleteVariant(id, label)} // Aggiungi la logica per rimuovere la variante
                                                    >
                                                        {Array.from(selectedForDelete).some(item => item.id === id) ? (
                                                            <ReplayIcon fontSize="small" color="primary" />
                                                        ) : (
                                                            <DeleteIcon fontSize="small" color="secondary" />
                                                        )}
                                                    </IconButton>
                                                </div>

                                                {/* Divider separato */}
                                                {el.options.length - 1 !== index && (
                                                    <Divider style={{ width: '90%', margin: '5px 0', alignSelf: 'center' }} />
                                                )}
                                            </div>
                                        ))}
                                    </RadioGroup>
                                </>
                            )}

                        </FormControl>
                    </Grid>
                ))}
            </Grid>
            <Button
                style={{ position: 'fixed', bottom: 0, zIndex: '800', height: '60px' }}
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleConfirm}
            >
                Aggiungi al carrello
            </Button>
        </Dialog>
    );

}
export default OwnerRoomDialogVariants;

