import React, { useContext } from 'react'
import CardActions from '@mui/material/CardActions';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardContext } from "../../../contexts/dashboard.context";
import { UserContext } from '../../../contexts/user.context';
import { getDisplayDate, getMaxDateOfArray, toDate, calculateTotalWithFees } from "../../../functions";
import "./printBill.css";
import { formatVariants } from '../../../functions';

import {
    AppBar,
    Button,
    Grid,
    Toolbar
} from "@mui/material";
import axios from 'axios';

function RoomViewPrint({ tableId, handleClose, table, statesSconti, paymentIntentId }) {
    const { removeTable } = useContext(DashboardContext);
    const { user } = useContext(UserContext);

    let totalPrice, i = -1, mergedReservations;
    if (table !== null) {
        const groupedReservations = {};
        table.reservations.forEach((reservations) => {
            const key = `${reservations.tableId || reservations.deliveryId}-${reservations.variant}-${reservations.note}-${reservations.dishId}-${reservations.selected_option}-${reservations.name}`;
            if (reservations.status !== 'owner_deleted') {
                if (groupedReservations[key]) {
                    // Duplicato trovato, aumenta la quantità
                    groupedReservations[key].amount += reservations.amount;
                } else {
                    // Non duplicato, aggiungi una nuova voce nell'oggetto raggruppato
                    groupedReservations[key] = { ...reservations };
                }
            }
        });
        // Converti l'oggetto raggruppato in un array
        mergedReservations = Object.values(groupedReservations);
        // Aggiorna il campo "reservations" con i nuovi dati
        i = mergedReservations.findIndex((tb) => (tb.tableId || tb.deliveryId) === tableId);
        totalPrice = i === -1 ? null : mergedReservations.reduce(
            (acc, { price, amount, status }) => {
                if (status !== "owner_deleted") {
                    return acc + price * amount;
                } else {
                    return acc;
                }
            },
            0
        );

    }

    const handleClickPrintRemove = (remove) => {
        if (remove) {
            //avvia stampa
            let costoConsegna = 0;
            if (table?.tipo?.toLowerCase() === 'consegna') { costoConsegna = Number(statesSconti.costoConsegna); }
            const totalePagato = statesSconti.scontoTotalPrice !== 0 ? statesSconti.nuovoTotalPrice : totalPrice + costoConsegna;

            window.print();
            axios.post('/api/dashboard/owner/table/history', { table: table, totaleOrdine: totalPrice, costoConsegna, totalePagato }).then(() => {
                handleClose();
                removeTable((table.tableId || table.deliveryId), false, false, false, table.deliveryId ? true : false);
            })
        } else {
            window.print();
            handleClose();
        }

    }

    const lastUpdate =
        i === -1
            ? null
            : getMaxDateOfArray(
                mergedReservations.map(({ lastUpdate }) => toDate(lastUpdate))
            );


    return (
        <div id="scontrino" style={{ fontSize: '20px', backgroundColor: '#fff', paddingTop: '40px' }} >
            <Typography style={{ marginBottom: '15px', color: '#000', fontSize: '16px' }}>Non valida come ricevuta fiscale</Typography>

            {table?.deliveryId && (
                <div style={{ marginBottom: '50px', border: '1px solid rgba(0,0,0,0.3)', padding: '10px' }}>
                    <Typography style={{ color: '#000', fontSize: '18px', marginBottom: '10px', fontWeight: 'bold' }}>{table?.tipo + ' alle ore: ' + table?.orario}</Typography>
                    <Typography style={{ color: '#000', fontSize: '22px', letterSpacing: 1, fontWeight: 'bold' }}>{table?.cognome ? table?.nome + ' ' + table?.cognome : table?.nome}</Typography>
                    {table?.telefono && (<Typography style={{ color: '#000', fontSize: '24px', fontWeight: 'bold' }}>{table?.telefono}</Typography>)}
                    {table?.indirizzo && (<Typography style={{ color: '#000', fontSize: '22px', fontWeight: 'bold' }}>{table?.indirizzo + ' - ' + table?.comune}</Typography>)}
                    {table?.noteConsegna && (<Typography style={{ color: '#000', fontSize: '22px', fontWeight: 'bold' }}><span style={{ fontWeight: 'bold' }}>Note di consegna:</span> {table?.noteConsegna}</Typography>)}

                </div>
            )}

            <AppBar id="appBar_Mobile" style={{ display: 'block' }}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <Typography style={{ marginBottom: '10px', textAlign:'center', borderBottom:'1px solid black', borderTop: '1px solid black' }} variant="h3">Rues45</Typography> */}

                    <Typography id="titolo_mobile" variant="h5">{user.name} {table?.tableId && <span> - Tavolo {table?.tableId}</span>}</Typography>
                </Toolbar>
            </AppBar>

            <div style={{ boxShadow: 'none', color: '#000' }}>
                <div>
                    {mergedReservations?.map(el => (
                        <div key={el.id}>
                            {el.status !== 'owner_deleted' &&
                                <div style={{ borderBottom: '1px', borderBottomStyle: 'solid', marginBottom: '10px', borderColor: '#b3b3b3', display: 'flex' }}>
                                    <div style={{ flex: '1' }}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <span style={{ fontWeight: 'bold' }}>x{el.amount} {el.name}</span>
                                            </Grid>
                                        </Grid>

                                        {el.variant && (
                                            <Grid container spacing={2} >
                                                <Grid item>
                                                    {formatVariants(el.variant, 'stampa')}
                                                </Grid>
                                            </Grid>
                                        )}

                                        {el.note && (
                                            <Grid container spacing={2} >
                                                <Grid item>
                                                    note: <strong>{el.note}</strong>
                                                </Grid>
                                            </Grid>
                                        )}

                                        <Grid container spacing={2} >
                                            <Grid style={{ width: '100%', textAlign: 'right' }} item >
                                                {el.price && el.amount ? (el.price * el.amount).toFixed(2).toString().replace('.', ',') : 0} €
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            }
                        </div>

                    ))}

                    {table?.tipo ? (
                        <>
                            {table?.tipo.toLowerCase() === 'consegna' ? (
                                <>
                                    <Grid style={styles.otherStyle}>
                                        Costo consegna: {Number(statesSconti.costoConsegna).toFixed(2).toString().replace('.', ',')} €
                                    </Grid>
                                    {paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato' && (<Grid style={styles.otherStyle}>
                                        Costi servizio: {calculateTotalWithFees(totalPrice + Number(statesSconti.costoConsegna), true, paymentIntentId ? 'carta' : 'cassa').replace('.', ',')} €
                                    </Grid>)}
                                    <Grid style={statesSconti.scontoTotalPrice !== 0 ? styles.lineThrough : styles.lineNotThrough}>
                                        Totale: {calculateTotalWithFees(totalPrice + Number(statesSconti.costoConsegna), false, paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato' ? 'carta' : 'cassa').replace('.', ',')} €
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato' && (<Grid style={styles.otherStyle}>
                                        Costi servizio: {calculateTotalWithFees(totalPrice, true, paymentIntentId ? 'carta' : 'cassa').replace('.', ',')} €
                                    </Grid>)}
                                    <Grid style={statesSconti.scontoTotalPrice !== 0 ? styles.lineThrough : styles.lineNotThrough}>
                                        Totale: {calculateTotalWithFees(totalPrice, false, paymentIntentId && paymentIntentId.toLowerCase() !== 'pagato' ? 'carta' : 'cassa').replace('.', ',')} €
                                    </Grid>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid style={statesSconti.scontoTotalPrice !== 0 ? styles.lineThrough : styles.lineNotThrough}>
                                Totale: {totalPrice ? totalPrice.toFixed(2).toString().replace('.', ',') : 0} €
                            </Grid>
                        </>
                    )}

                    {statesSconti.scontoTotalPrice !== 0 ? (
                        <>
                            <Grid style={styles.otherStyle}>
                                Sconto di: {statesSconti.scontoTotalPrice.toFixed(2).toString().replace('.', ',')} €
                            </Grid>
                            <Grid style={styles.lineNotThrough}>
                                Nuovo totale: {statesSconti.nuovoTotalPrice.toFixed(2).toString().replace('.', ',')} €
                            </Grid>
                        </>
                    ) : (<></>)}

                    <Grid container style={{ margin: '20px 0', textAlign: 'center' }}>
                        <Grid item xs={12} >
                            <Typography
                                gutterBottom
                                variant="body2"
                                component="h2"
                                style={{ color: '#000' }}
                            >
                                Ultima ordinazione: {getDisplayDate(lastUpdate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <CardActions id="button_Mobile">
                    <Button onClick={() => handleClickPrintRemove(true)} variant="contained" color="secondary">Stampa e Elimina</Button>
                    <Button onClick={() => handleClickPrintRemove(false)} variant="contained" color="primary">Stampa</Button>
                </CardActions>
            </div>

        </div >
    )
}

export default RoomViewPrint

const styles = {
    lineThrough: {
        textDecoration: 'line-through',
        width: '100%',
        textAlign: 'right',
        marginTop: '20px'
    },
    lineNotThrough: {
        width: '100%',
        textAlign: 'right',
        fontWeight: 'bold',
        marginTop: '20px'
    },
    otherStyle: {
        width: '100%',
        textAlign: 'right',
        marginTop: '20px'
    }
}