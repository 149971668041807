import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

const useUser = () => {
	const [ownerloginData, setOwnerLoginData] = useState(null);
	const [userloginData, setUserLoginData] = useState(null);
	const [user, setUser] = useState(null);
	const [restorePasswordData, setRestorePasswordData] = useState({});
	const [ownerLoginSuccess, setOwnerLoginSuccess] = useState(null);
	const [userLoginSuccess, setUserLoginSuccess] = useState(null);

	// ****************** OWNER ****************************
	const ownerImplicitLogin = () => {
		setOwnerLoginData({ implicitLogin: true });
	};

	const ownerExplicitLogin = (data) => {
		setOwnerLoginData({ explicitLogin: { email: data.email, password: data.password } });
	};

	// ****************** USER ****************************
	const userImplicitLogin = () => {
		setUserLoginData({ implicitLogin: true });
	};

	const userExplicitLogin = (data, isAnonymus) => {
		setUserLoginData({
			explicitLogin: data
				? { email: data.email, password: data.password, isAnonymus: false }
				: { email: '', password: '', isAnonymus },
		});
	};

	const userFbLogin = (response) => {
		if (!response.status) {
			axios.post('/api/user/fbLogin', response).then(({ data }) => {
				if (data && data.success) {
					setUser({ ...data.user, valid: true });
				} else {
					setUser({ valid: false });
				}
			});
		}
	};

	const userGlogin = (response) => {
		if (!response.error) {
			axios.post('/api/user/GLogin', response).then(({ data }) => {
				if (data && data.success) {
					setUser({ ...data.user, valid: true });
				} else {
					setUser({ valid: false, error: data.error });
				}
			});
		}
	};

	const userSignup = (data) => {
		setUserLoginData({
			signup: {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				password: data.password,
				acceptConditions: data.acceptConditions,
			},
		});
	};

	//*********************

	//user signup
	useEffect(() => {
		if (userloginData && userloginData.signup) {
			setUser(null);
			let subscribed = true;
			axios.post('/api/user/signup', userloginData.signup).then(({ data }) => {
				if (subscribed) {
					if (data && data.success) {
						setUser({ ...data.user, valid: true });
					} else {
						setUser({ valid: false, error: data.error });
					}
					setUserLoginData(null);
				}
			});
			return () => (subscribed = false);
		}
	}, [userloginData]);


	//implicit login (tries first user then owner)
	useEffect(() => {
		if (
			(ownerloginData && ownerloginData.implicitLogin) ||
			(userloginData && userloginData.implicitLogin && !user)
		) {
			let subscribed = true;
			axios
				.post(userloginData ? '/api/user/authenticate' : '/api/owner/authenticate', {})
				.then(({ data }) => {
					if (subscribed) {
						if (data && data.success) {
							setUser({ ...data.user, valid: true });
							userloginData
								? setUserLoginSuccess(true)
								: setOwnerLoginSuccess(true);
						} else {
							setUser({ valid: false });
							userloginData
								? setUserLoginSuccess(false)
								: setOwnerLoginSuccess(false);
						}
						userloginData ? setUserLoginData(null) : setOwnerLoginData(null);
					}
				});
			return () => (subscribed = false);
		}// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownerloginData, userloginData]);

	//explicit login (tries first user then owner) (modificato)
	useEffect(() => {
		if ((userloginData && userloginData.explicitLogin)) {
			let subscribed = true;
			axios
				.post('/api/user/login', {
					email: userloginData.explicitLogin.email,
					password: userloginData.explicitLogin.password,
					isAnonymus: userloginData ? userloginData.explicitLogin.isAnonymus : false,
				})
				.then(({ data }) => {
					if (subscribed) {
						if (data) {
							if (data && data.success) {
								setUser({ ...data.user, valid: true });
							} else {
								if (!userloginData) {
									setUser({ valid: false, error: data.error });
								}
							}
						}
						setUserLoginData(null);
					}
				});
			return () => (subscribed = false);
		}
	}, [userloginData]);


	// !questo è un explicit login deve essere solo lato owner (copia di quello di sopra ma modificato)
	useEffect(() => {
		if (
			(ownerloginData && ownerloginData.explicitLogin)
		) {
			let subscribed = true;
			axios
				.post('/api/owner/login', {
					email: ownerloginData.explicitLogin.email,
					password: ownerloginData.explicitLogin.password,
					isAnonymus: userloginData ? userloginData.explicitLogin.isAnonymus : false,
				})
				.then(({ data }) => {
					if (subscribed) {
						if (data) {
							if (data && data.success) {
								setUser({ ...data.user, valid: true });
							} else {
								if (!userloginData) {
									setUser({ valid: false, error: data.error });
								}
							}
						}
						userloginData ? setUserLoginData(null) : setOwnerLoginData(null);
					}
				});
			return () => (subscribed = false);
		}// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownerloginData]);

	//delete login data if login was successful
	useEffect(() => {
		if (user && user.valid) {
			setUserLoginData(null);
			setOwnerLoginData(null);
		}
	}, [user]);

	//reset restore password data onload of every page
	useEffect(() => {
		setRestorePasswordData({});
	}, []);

	const eraseUser = (noLogout) => {
		setUser(null);
		if (!noLogout) {
			axios.post('/api/user/logout');
		}
	};

	const restorePasswordRequest = (mail, userType) => {
		setRestorePasswordData({ ...restorePasswordData, success: null, error: null });
		axios.post(`/api/${userType}/password/restore/request`, { mail }).then(({ data }) => {
			if (data.success) {
				setRestorePasswordData({ ...restorePasswordData, success: true, error: null });
			} else {
				setRestorePasswordData({
					...restorePasswordData,
					success: false,
					error: data.error,
				});
			}
		});
	};

	const resetPassword = (id, userId, password, userType) => {
		setRestorePasswordData({ ...restorePasswordData, success: null, error: null });
		axios
			.post(`/api/${userType}/password/restore/set`, { id, userId, password })
			.then(({ data }) => {
				if (data.success) {
					setRestorePasswordData({
						...restorePasswordData,
						success: true,
						error: null,
					});
				} else {
					setRestorePasswordData({
						...restorePasswordData,
						success: false,
						error: data.error,
					});
				}
			});
	};

	const updateUser = (userData) => {
		setUser({ ...user, ...userData });
	};

	const switchToOwner = (ownerUser, callBack) => {
		axios
			.post('/api/user/sessionId/set', {
				sessionId: ownerUser.sessionId,
				userId: ownerUser.user.id,
			})
			.then(({ data }) => {
				if (data.success) {
					setUser(ownerUser.user);
					sessionStorage.removeItem('userCache');
					sessionStorage.removeItem('precUrl');
					callBack();
				}
			});
	};

	return {
		ownerImplicitLogin,
		userImplicitLogin,
		ownerExplicitLogin,
		userExplicitLogin,
		userSignup,
		eraseUser,
		userFbLogin,
		userGlogin,
		restorePasswordRequest,
		resetPassword,
		restorePasswordData,
		updateUser,
		setUser,
		user,
		switchToOwner,
		ownerLoginSuccess,
		userLoginSuccess,
	};
};

export const UserProvider = (props) => {
	const user = useUser();

	return (
		<>
			<UserContext.Provider value={user}>{props.children}</UserContext.Provider>
		</>
	);
};
