import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
// import ErrorBoundary from './ErrorBoundary';
import { CenteredSpinner } from './components/customComponents/component.loader.jsx';
// import './i18nextConf';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
		<React.Suspense fallback={<CenteredSpinner />}>
			<App />
		</React.Suspense>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
