import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

export default function ComponentLoader({ children, isLoading, spacing, ...rest }) {
	return isLoading ? (
		<CenteredSpinner />
	) : (
		React.Children.toArray(children).map((child) => React.cloneElement(child, rest))
	);
}

export function CenteredSpinner() {
	const useStyles = makeStyles(() => ({
		fullScreen: {
			position: 'fixed',
			top:'50%',
			left:'50%',
			transform: 'translate(-50%, -50%)',
			width:'100%',
			backgroundColor: 'rgba(48, 48, 48, 0.5)',
			height:'100%',
			zIndex: 999999,
			display:'flex',
			justifyContent:'center',
			alignItems:'center'
		},
	}));

	const classes = useStyles();
	return (
		<div className={classes.fullScreen}>
			<CircularProgress />
		</div>
	);
}
