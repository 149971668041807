import { Button, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';
import { ReservationContext } from '../../../contexts/reservation.context';
import Dish from '../../user/cart/user.cart.dish';
import { OwnerRoomTableContext } from './owner.room.table';
import DeliveryOrder from '../room/owner.room.deliveryOrder'


const useStyles = makeStyles((theme) => ({
    spacingTop: {
        marginTop: theme.spacing(2) + ' !important',
    },
    spacingBottom: {
        marginBottom: theme.spacing(2)+ ' !important',
    },
    content: {
        // marginRight: theme.spacing(1)+ ' !important',
        padding: '10px',
        //backgroundColor:'#000 !important'
    },
    centeredText: {
        marginTop: '60px',
        textAlign: 'center',
    },
}));

const UserCart = ({ handleClickOrder, getAmountTotal, tableId, ownerId, setOpenDialog, openDialog, deliveryId }) => {
    const classes = useStyles();
    const { cart, getTotalPrice, successOrder, getDishPrice, order, orderRoomCart } =
        useContext(ReservationContext);
    const [totalPrice, setTotalPrice] = useState(getTotalPrice());
    const _OwnerRoomTableContext = useContext(OwnerRoomTableContext);
    const [openDialogOrderDelivery, setOpenDialogOrderDelivery] = useState(false);

    useEffect(() => {
        setTotalPrice(getTotalPrice());
        if (getAmountTotal) getAmountTotal(cart); //? Perché nella parte mobile, quando si clicca su "Ordina" viene richiamata questa funzione (non si sa perché!)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    const _handleOrder = () => {
        if (_OwnerRoomTableContext) order(_OwnerRoomTableContext);
        else orderRoomCart({ deliveryId, ownerId })
        handleClickOrder();
    }


    return (
        <>
            <DeliveryOrder setOpenDialogOrderDelivery={setOpenDialogOrderDelivery} openDialogOrderDelivery={openDialogOrderDelivery} cart={cart} totalPrice={totalPrice} getDishPrice={getDishPrice} ownerId={ownerId} openDialog={openDialog} setOpenDialog={setOpenDialog} handleClickOrder={handleClickOrder} />

            <div className={classes.content}>
                {cart &&
                    cart.length !== 0 &&
                    cart.map((dish, i) => (
                        <React.Fragment key={dish.id + '_' + i + '_' + dish.amount + '_' + dish.note}>
                            <Dish dish={dish} dishIndex={i} />
                            <Divider className={classes.spacingTop} />
                        </React.Fragment>
                    ))}
                {cart && cart.length !== 0 ? (
                    <>
                        {(tableId || deliveryId) ? (
                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                className={`${classes.spacingTop} ${classes.spacingBottom}`}
                                onClick={() => _handleOrder()}
                                disabled={successOrder}
                            >
                                Ordina {totalPrice.toFixed(2)}€
                            </Button>
                        ) : (
                            <Button
                                variant='contained'
                                color='secondary'
                                fullWidth
                                className={`${classes.spacingTop} ${classes.spacingBottom}`}
                                onClick={() => setOpenDialogOrderDelivery(!openDialogOrderDelivery)}
                                disabled={successOrder}
                            >
                                Concludi ordine ( {totalPrice.toFixed(2)} € )
                            </Button>
                        )}
                    </>

                ) :
                    (
                        <Typography
                            variant='subtitle1'
                            color='textSecondary'
                            className={classes.centeredText}
                        >
                            Il carrello è vuoto.
                        </Typography>
                    )
                }
            </div>
        </>
    );
};

export default UserCart;
