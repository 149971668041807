import React, { useState, useEffect, useContext } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import { Button, TextField, Toolbar, Typography, Grid, RadioGroup, Radio, FormControlLabel, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import ComponentLoader from '../../customComponents/component.loader'
import OrderSummary from '../../user/delivery/user.delivery.sendOrder';
import { UserContext } from '../../../contexts/user.context';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

function DeliveryOrder({ setOpenDialogOrderDelivery, openDialogOrderDelivery, totalPrice, cart, getDishPrice, ownerId, openDialog, setOpenDialog, handleClickOrder }) {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [deliveryTime, setDeliveryTime] = useState('');
    const [futureTimes, setFutureTimes] = useState([]);
    const [deliveryOption, setDeliveryOption] = useState('');
    const [optionTime, setOptionTime] = useState('');
    const [noteDelivery, setNoteDelivery] = useState('');
    const [costoConsegna, setCostoConsegna] = useState('');
    const [loading, setLoading] = useState(false);
    const { user } = useContext(UserContext);

    const [errors, setErrors] = useState({
        name: false,
        phoneNumber: false,
        address: false,
        city: false,
        deliveryOption: false,
        optionTime: false
    });

    const [formSubmitted, setFormSubmitted] = useState(false); // Stato per gestire la visualizzazione di OrderSummary
    const menuCity = ['Baiano', 'Avella', 'Mugnano del Cardinale', 'Quadrelle', 'Sirignano', 'Sperone'];

    useEffect(() => {
        setLoading(true);
        if (user) {
            const {
                costoConsegna,
                openingTime,
                closingTime,
            } = user;

            setCostoConsegna(costoConsegna);

            const currentTime = new Date();
            const currentHour = currentTime.getHours();
            const currentMinutes = currentTime.getMinutes();

            let futureTimes = [];
            let openingHour, openingMinutes, closingHour, closingMinutes;

            openingHour = Number(openingTime.split(':')[0]);
            openingMinutes = Number(openingTime.split(':')[1]);
            closingHour = Number(closingTime.split(':')[0]);
            closingMinutes = Number(closingTime.split(':')[1]);

            if (closingHour >= openingHour) {
                for (let hour = openingHour; hour <= closingHour; hour++) {
                    const startMinutes = hour === openingHour ? openingMinutes : 0;
                    for (let minutes = startMinutes; minutes < 60; minutes += 15) {
                        if (hour === closingHour && minutes > closingMinutes) {
                            break;
                        }
                        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
                            const formattedHour = (hour % 24).toString().padStart(2, '0');
                            const formattedMinutes = minutes.toString().padStart(2, '0');
                            futureTimes.push(`${formattedHour}:${formattedMinutes}`);
                        }
                    }
                }
            } else {
                for (let hour = openingHour; hour < 24; hour++) {
                    const startMinutes = hour === openingHour ? openingMinutes : 0;
                    for (let minutes = startMinutes; minutes < 60; minutes += 15) {
                        if (hour === closingHour && minutes > closingMinutes) {
                            break;
                        }
                        if (hour > currentHour || (hour === currentHour && minutes >= currentMinutes)) {
                            const formattedHour = (hour % 24).toString().padStart(2, '0');
                            const formattedMinutes = minutes.toString().padStart(2, '0');
                            futureTimes.push(`${formattedHour}:${formattedMinutes}`);
                        }
                    }
                }
                for (let hour = 0; hour <= closingHour; hour++) {
                    const startMinutes = hour === openingHour ? openingMinutes : 0;
                    for (let minutes = startMinutes; minutes < 60; minutes += 15) {
                        if (hour === closingHour && minutes > closingMinutes) {
                            break;
                        }
                        const formattedHour = (hour % 24).toString().padStart(2, '0');
                        const formattedMinutes = minutes.toString().padStart(2, '0');
                        futureTimes.push(`${formattedHour}:${formattedMinutes}`);
                    }
                }
            }

            setFutureTimes(futureTimes);
            setLoading(false);
        }
    }, [deliveryOption, user]);

    const handleNameChange = (event) => {
        setName(event.target.value);
        setErrors({ ...errors, name: false });
    };

    const handleDeliveryOptionChange = (event) => {
        setAddress('');
        setCity('');
        setNoteDelivery('');

        setDeliveryOption(event.target.value);
        setErrors({ ...errors, deliveryOption: false });
    };

    const handlePhoneNumberChange = (event) => {
        const formattedPhoneNumber = event.target.value.replace(/\D/g, '');
        setPhoneNumber(formattedPhoneNumber);
        setErrors({ ...errors, phoneNumber: false });
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
        setErrors({ ...errors, address: false });
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
        setErrors({ ...errors, city: false });
    };

    const handleDeliveryTimeChange = (event) => {
        setDeliveryTime(event.target.value);
        setErrors({ ...errors, deliveryTime: false });
    };

    const handleDeliveryOptionHours = (event) => {
        if (event.target.value === 'ora') setDeliveryTime(futureTimes[0] + ' - ' + futureTimes[1]);
        else setDeliveryTime('')
        setOptionTime(event.target.value);
        setErrors({ ...errors, deliveryOption: false, optionTime: false, deliveryTime: false });
    };

    const handleNoteDelivery = (event) => {
        setNoteDelivery(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let formIsValid = true;
        const newErrors = { ...errors };

        if (!name) {
            newErrors.name = true;
            formIsValid = false;
        }

        if (!deliveryOption) {
            newErrors.deliveryOption = true;
            formIsValid = false;
        }

        // if (!phoneNumber) {
        //     newErrors.phoneNumber = true;
        //     formIsValid = false;
        // }

        if (!address && deliveryOption === 'Consegna') {
            newErrors.address = true;
            formIsValid = false;
        }

        if (!city && deliveryOption === 'Consegna') {
            newErrors.city = true;
            formIsValid = false;
        }

        // Option time è vuoto
        if (!optionTime) {
            newErrors.optionTime = true
            formIsValid = false;
        }

        if (!deliveryTime && optionTime === 'impostaOrario') {
            newErrors.deliveryTime = true;
            formIsValid = false;
        }

        setErrors(newErrors);

        if (formIsValid) {
            setFormSubmitted(true); // Imposta formSubmitted su true quando il form è valido

        }
    };

    const closeDialogForm = () => {
        handleClickOrder();
        setOpenDialogOrderDelivery(!openDialogOrderDelivery);
        setFormSubmitted(false);
        if (openDialog) setOpenDialog(!openDialog)
    }

    return (
        <Dialog
            open={openDialogOrderDelivery}
            onClose={() => setOpenDialogOrderDelivery(!openDialogOrderDelivery)}
            fullScreen={true}
            maxWidth={'md'}
        >
            <DialogTitle>
                <AppBar>
                    <Toolbar>
                        <Typography variant="h6">{formSubmitted ? 'Riepilogo ordine' : 'Completa ordine'}</Typography>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent>
                <ComponentLoader isLoading={loading}>
                    {futureTimes.length > 2 ? (
                        <>
                            {(!formSubmitted) ? ( // Mostra il form solo se il form non è stato inviato con successo
                                <form onSubmit={handleSubmit}>
                                    <Typography style={{ marginTop: '40px', textAlign: 'center' }}>Si tratta di:</Typography>

                                    <Grid container>
                                        <Grid item xs>
                                            <RadioGroup value={deliveryOption} onChange={handleDeliveryOptionChange}>
                                                <FormControlLabel value="Ritiro" control={<Radio />} label={`Ritiro`} />
                                                <FormControlLabel value="Consegna" control={<Radio />} label={`Consegna`} />
                                            </RadioGroup>
                                            {errors.deliveryOption && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                        </Grid>
                                    </Grid>

                                    <Typography style={{ marginTop: '15px', textAlign: 'center' }}>Inserisci i dati del cliente</Typography>

                                    <Grid container style={{ marginTop: '25px' }}>
                                        <Grid item xs>
                                            <TextField
                                                style={{ width: '100%' }}
                                                label="Nome"
                                                variant="outlined"
                                                value={name}
                                                onChange={handleNameChange}
                                            />
                                            {errors.name && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                        </Grid>
                                    </Grid>

                                    <Grid container style={{ marginTop: '15px' }}>
                                        <Grid item xs>
                                            <TextField
                                                style={{ width: '100%' }}
                                                label="Telefono"
                                                variant="outlined"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumberChange}
                                            />
                                            {/* {errors.phoneNumber && <FormHelperText error>Campo obbligatorio</FormHelperText>} */}
                                        </Grid>
                                    </Grid>

                                    {deliveryOption === 'Consegna' && (
                                        <>
                                            <Grid container style={{ marginTop: '15px' }}>
                                                <Grid item xs>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        label="Indirizzo e N°"
                                                        variant="outlined"
                                                        value={address}
                                                        onChange={handleAddressChange}
                                                    />
                                                    {errors.address && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                                </Grid>
                                            </Grid>

                                            <Grid container style={{ marginTop: '15px' }}>
                                                <Grid item xs>
                                                    <FormControl style={{ width: '100%' }}>
                                                        <InputLabel id="delivery-city-label">
                                                            {'Comune'}
                                                        </InputLabel>
                                                        <Select
                                                            label="Comune"
                                                            value={city}
                                                            onChange={handleCityChange}
                                                        >
                                                            {menuCity.map((comune, index) => (
                                                                <MenuItem key={index} value={comune}>
                                                                    {comune}
                                                                </MenuItem>
                                                            ))}

                                                        </Select>
                                                    </FormControl>
                                                    {errors.city && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                                </Grid>
                                            </Grid>

                                            <Grid container style={{ marginTop: '15px' }}>
                                                <Grid item xs>
                                                    <TextField
                                                        style={{ width: '100%' }}
                                                        label="Note di consegna"
                                                        variant="outlined"
                                                        value={noteDelivery}
                                                        onChange={handleNoteDelivery}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    {deliveryOption && (<>
                                        <Typography style={{ marginTop: '40px', textAlign: 'center' }}>Quando?</Typography>

                                        <Grid container>
                                            <Grid item xs>
                                                <RadioGroup value={optionTime} onChange={handleDeliveryOptionHours} >
                                                    <FormControlLabel value="ora" control={<Radio />} label={
                                                        <Typography>Prima possibile <span style={{ color: '#fb4736', fontWeight: 'bold' }}>( {futureTimes[0]} - {futureTimes[1]} )</span></Typography>
                                                    } />
                                                    <FormControlLabel value="impostaOrario" control={<Radio />} label={futureTimes.length <= 2 ? "Imposta orario non disponibile" : "Imposta orario"} disabled={futureTimes.length <= 2} />
                                                </RadioGroup>
                                                {errors.optionTime && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                            </Grid>
                                        </Grid>
                                    </>)}

                                    {optionTime === 'impostaOrario' && (
                                        <>
                                            <Grid container style={{ marginTop: '15px' }}>
                                                <Grid item xs>
                                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                                        <InputLabel id="delivery-time-label">
                                                            {deliveryOption === 'Consegna' ? 'Orario di consegna' : 'Orario'}
                                                        </InputLabel>
                                                        <Select
                                                            labelId="delivery-time-label"
                                                            id="delivery-time-select"
                                                            value={deliveryTime}
                                                            onChange={handleDeliveryTimeChange}
                                                            label={deliveryOption === 'Consegna' ? 'Orario di consegna' : 'Orario'}
                                                        >
                                                            {futureTimes.map((time, index) => (
                                                                index >= 2 && (
                                                                    <MenuItem key={index} value={time}>
                                                                        {time}
                                                                    </MenuItem>
                                                                )
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {errors.deliveryTime && <FormHelperText error>Campo obbligatorio</FormHelperText>}
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                    <div style={{ width: '100%', flexWrap: 'wrap', gap: '5px', marginTop: '30px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            size='small'
                                            onClick={() => setOpenDialogOrderDelivery(!openDialogOrderDelivery)}
                                            startIcon={<ArrowBackIcon />}
                                        >
                                            Torna Indietro
                                        </Button>
                                        <Button
                                            size='small'
                                            variant="contained"
                                            color="primary"
                                            endIcon={<ArrowForwardIcon />}
                                            type="submit"
                                            disabled={loading}
                                        >
                                            vai al checkout
                                        </Button>
                                    </div>


                                </form>
                            ) : <></>}

                            {formSubmitted && (
                                <OrderSummary
                                    formData={{
                                        name,
                                        surname: '',
                                        email: '',
                                        phoneNumber,
                                        address,
                                        city,
                                        deliveryTime,
                                        deliveryOption,
                                        noteDelivery,
                                        costoConsegna
                                    }}
                                    ownerId={ownerId}
                                    isOwner={true}
                                    totalPrice={totalPrice}
                                    cart={cart}
                                    getDishPrice={getDishPrice}
                                    setFormSubmitted={setFormSubmitted}
                                    closeDialogForm={closeDialogForm}
                                    goBack={() => setFormSubmitted(false)} // Funzione per tornare indietro
                                    handleClickOrder={handleClickOrder}
                                />
                            )}
                        </>
                    ) : (

                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '40px', alignItems: 'center', height: 'calc(100dvh - 64px)' }}>
                            <div style={{ textAlign: 'center' }}>
                                <SentimentDissatisfiedOutlinedIcon color='primary' style={{ fontSize: '200px' }} />
                            </div>
                            <div>
                                <Typography style={{ textAlign: 'center', letterSpacing: '1px', fontWeight: 'bold' }}>Il locale è chiuso in questo momento</Typography>
                                <Typography style={{ textAlign: 'center', letterSpacing: '1px', fontWeight: 'bold' }}>Apertura prevista dalle {user.openingTime.substring(0, 5)} alle {user.closingTime.substring(0, 5)} </Typography>
                                <Typography style={{ textAlign: 'center', letterSpacing: '1px', fontWeight: 'bold' }}>Verifica o modifica gli orari di apertura nella sezione profilo</Typography>
                            </div>
                            <Button
                                variant="contained"
                                color="secondary"
                                size='small'
                                onClick={() => setOpenDialogOrderDelivery(!openDialogOrderDelivery)}
                                startIcon={<ArrowBackIcon />}
                            >
                                Torna Indietro
                            </Button>
                        </div>

                    )}

                </ComponentLoader>
            </DialogContent>
        </Dialog>
    );
}

export default DeliveryOrder;
