import { Switch, Typography } from '@mui/material';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { DashboardContext } from '../../../contexts/dashboard.context';

function ToggleChat() {
	const { socket } = useContext(DashboardContext);

	const [enabled, setEnabled] = React.useState(true);

	useEffect(() => {
		let isMounted = true;
	
		if (socket) {
			axios.post('/api/owner/orderEnabled/get').then(({ data }) => {
				if (isMounted) {
					setEnabled(data.orderEnabled === 1);
				}
			});
	
			socket.on('enableOrder', () => {
				if (isMounted) {
					setEnabled(true);
				}
			});
	
			socket.on('disableOrder', () => {
				if (isMounted) {
					setEnabled(false);
				}
			});
	
			return () => {
				isMounted = false;
				socket.off('enableOrder');
				socket.off('disableOrder');
			};
		}
	}, [socket]);

	const handleToggle = () => {
		axios.post('/api/owner/order/toggle');
		setEnabled((e) => !e);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Switch checked={enabled} onChange={handleToggle} color='primary' />
			<Typography component={'span'} color='textSecondary'>
				Abilita ordini
			</Typography>
		</div>
	);
}

export default ToggleChat;
