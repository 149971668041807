import React, { useState, useContext } from 'react';
import {
  Dialog,
  Slide,
  AppBar,
  IconButton,
  Typography,
  Toolbar,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import MuiDialogContent from "@mui/material/DialogContent";
import UserCart from "./owner.room.cart"
import OwnerRoomDialogVariants from "./owner.room.dialog.variants"
import CloseIcon from "@mui/icons-material/Close";
import { ReservationContext } from '../../../contexts/reservation.context';
import { makeStyles } from '@mui/styles';
import OutMenu from "../menu/owner.menu.outmenu"

const useStyles = makeStyles((theme) => ({
  categoryCard: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(0.5) + ' !important',
    width: '100%',
    borderRadius: theme.spacing(1) + ' !important',
    color: '#fff !important',
    height: 'auto',
    padding: theme.spacing(0.1), // Puoi regolare questo valore per adattarlo alle tue esigenze
  },
  dishCard: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    color: '#fff',
    height: 'auto',
    padding: theme.spacing(0.1), // Puoi regolare questo valore per adattarlo alle tue esigenze
  },
  dialogContent: {
    marginTop: theme.spacing(1)
  },
  sectionTitle: {
    textTransform: 'capitalize',
    marginBottom: theme.spacing(0.5) + ' !important',
    fontWeight: 'bold',
  },
  categoryButton: {
    margin: '4px !important',
    padding: '4px 10px !important',
    borderRadius: '8px !important',
    color: '#fff !important',
    backgroundColor: '#006633 !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  categoryButtonSelection: {
    margin: '4px !important',
    padding: '4px 10px !important',
    borderRadius: '8px !important',
    color: '#fff !important',
    backgroundColor: 'red !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  numberOrder: {
    color: '#fff',
    backgroundColor: 'red',
    borderRadius: '50%', // Utilizzare 50% per creare un cerchio perfetto
    padding: '2px 10px', // Puoi regolare il padding in base alle tue esigenze
    // display: 'inline-block', // Per assicurarti che il cerchio si adatti correttamente al contenuto
    //minWidth: '20px', // Larghezza minima per assicurarti che il cerchio sia visibile
    marginLeft: '10px',
    width:'27px',
    height:'27px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    position:'absolute',
    top:'-10px',
    right:'-10px',
    fontSize: '14px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogMenu({ setOpenDialog, openDialog, handleClickOrder, tableId, dataMenuObj, ownerId, deliveryId, form }) {
  const classes = useStyles();
  const [dish, setDish] = useState([])
  const [categorySelected, setCategorySelected] = useState("");
  const [showOutMenu, setShowOutMenu] = useState(false);
  const [dialogVariants, setDialogVariants] = useState({
    isOpen: false,
    data: {}
  });
  const [amountTotalCart, setAmountTotalCart] = useState({});
  const { addToCart, cart, setCart } =
    useContext(ReservationContext); //TODO SERVE QUESTO? C'è UN MODO DIVERSO??


  const getNameDish = (categoryId, nameCategoria) => {
    setCategorySelected(nameCategoria);

    // Filtra e ordina i piatti per nome in modo alfabetico
    let filterDish = dataMenuObj.dataMenu
      .filter(el => el.categoryId === categoryId)
      .sort((a, b) => a.name.localeCompare(b.name));

    setDish(filterDish);
  }


  const handleClickNameDish = (dish) => {
    if (dish.variants === null) dish.variants = []; // Perché in alcuni variants abbiamo null invece di un array vuoto;
    for (let x = 0; x < dish.variants.length; x++) {
      dish.variants[x].options = dish.variants[x].options.sort((a, b) => {
        if (a.label.toLowerCase().trim() < b.label.toLowerCase().trim()) {
          return -1;
        }
        if (a.label.toLowerCase().trim() > b.label.toLowerCase().trim()) {
          return 1;
        }
        return 0;
      });

    }
    if (dish.variants.length !== 0) {
      dish.variants.sort((a, b) => a.position - b.position); // Ordina in base alla position
      setDialogVariants({ isOpen: !dialogVariants.isOpen, data: dish });
    }
    else addToCart({ ...dish, amount: 1, note: "" })
  }

  const getAmountTotal = (totalCart) => {
    const amountMap = {};
    totalCart.forEach((dish) => {
      const { id, amount } = dish;
      amountMap[id] = (amountMap[id] || 0) + amount;
    });
    setAmountTotalCart(amountMap);
  }

  return (
    <MuiDialogContent>
      <Dialog onClose={handleClickOrder} TransitionComponent={Transition} fullScreen open={openDialog} >
        <OwnerRoomDialogVariants setDialogVariants={setDialogVariants} dialogVariants={dialogVariants} addToCart={addToCart} />
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClickOrder}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{deliveryId ? form : tableId ? 'Tavolo ' + tableId : 'Nuova consegna / Ritiro'}</Typography>
          </Toolbar>
        </AppBar>

        <div className={classes.dialogContent}>

          <Typography variant="h5" align="center" className={classes.sectionTitle}>Scegli la Categoria</Typography>
          <div className={classes.categoryCard}>
            {dataMenuObj.categories.map((category, index) => (
              <Button key={index} className={dish[0]?.categoryId === category.id ? classes.categoryButtonSelection : classes.categoryButton} onClick={() => getNameDish(category.id, category.name)} /* style={dish[0]?.categoryId === category.id ? { backgroundColor: 'red !important' } : {backgroundColor: 'red !important'}} */>
                {category.name}
              </Button>
            ))}
          </div>


          <Divider style={{ margin: '10px 0 20px 0' }} />

          <Grid container>
            <Grid item xs={7} >
              <div style={{ marginBottom: '100px' }}>
                <Typography variant="h6" align="center" className={classes.sectionTitle}>{categorySelected}</Typography>
                <div className={classes.dishCard} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '5px' }}>
                  {dish.map((el, index) => (
                    <div key={index} className={classes.dishCard}>
                      <Button style={{ textTransform: 'capitalize', fontSize: '18px', width: '100%', letterSpacing: '1px', position: 'relative' }} className={classes.categoryButton} onClick={() => handleClickNameDish(el)}>
                        {el.name}
                        {Object.keys(amountTotalCart).map((key, index) => el.id === key && (
                          <span key={index} className={classes.numberOrder}>{amountTotalCart[key]}</span>
                        ))}
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>

            <Grid item xs={1}></Grid>

            <Grid item xs={4} style={{ backgroundColor: '#010101', padding: '10px', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="h6" align="center" className={classes.sectionTitle}>Invia ordine</Typography>
              <UserCart tableId={tableId} ownerId={ownerId} deliveryId={deliveryId} getAmountTotal={getAmountTotal} handleClickOrder={handleClickOrder} openDialog={openDialog} setOpenDialog={setOpenDialog} />
            </Grid>
          </Grid>

        </div>
        <OutMenu dishes={cart} setDishes={setCart} setShowOutMenu={setShowOutMenu} showOutMenu={showOutMenu} />
      </Dialog>
    </MuiDialogContent>
  );
}

export default DialogMenu;
