import React, { useContext, useEffect, useState } from "react";

import { Divider, Typography, Button, Badge, Grid, Dialog, DialogTitle, DialogActions, DialogContentText, DialogContent } from '@mui/material'
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { ReservationContext } from "../../../contexts/reservation.context";
import { UserContext } from "../../../contexts/user.context";
import socketIOClient from "socket.io-client";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DialogPaymentStripe from "../../customComponents/payment.stripe";
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import { calcolaOrariDisponibili, filtraOrariConsegne, calculateTotalWithFees } from '../../../functions';
import { styleVariants, styleOptions } from "../../../style";
import OrderPayment from "./user.delivery.OrderPayment";
import OrderNotPayment from "./user.delivery.OrderNotPayment";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(6) + ' !important',
        paddingLeft: theme.spacing(0) + ' !important',
        paddingRight: theme.spacing(0) + ' !important'
    },
    summary: {
        marginBottom: theme.spacing(4),
    },
    orderItem: {
        marginBottom: theme.spacing(2),
    },
    fontItalic: {
        fontStyle: 'italic'
    },
    fontWeight: {
        fontWeight: 'bold'
    },
    centeredIcon: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: '100px',
        gap: '40px'
    }
}));

const stripePromise = loadStripe('pk_test_51PdrV5RsppFK1cnEQuq5VdhTTu9VCP5s3OWbyaxB6CH9LJ0hDw2ObfWZDFm1Dp68E05eZ5OTivyrJfINpnPtgX9G00Qca4sbLR');

function OrderSummary({
    formData,
    totalPrice,
    goBack,
    cart,
    getDishPrice,
    closeDialogForm,
    isOwner,
    ownerId,
    accountIdPayment,
    goToForm
}) {
    const classes = useStyles();
    const { eraseOrder } = useContext(ReservationContext);
    const { user } = useContext(UserContext);
    const [socket, setSocket] = useState(null);
    const getSocketId = () => (socket ? socket.id : null);
    const [successOrder, setSuccessOrder] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOpenDialogPayment, setIsOpenDialogPayment] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [paymentOption, setPaymentOption] = useState('carta');
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const [orarioOccupato, setOrarioOccupato] = useState(false)
    const [isNotConsegnaEnabled, setIsNotConsegnaEnabled] = useState(false);


    useEffect(() => {
        if (user && user.id) {
            const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
                secure: true,
                transports: ["websocket"],
                query: { ownerId: user.id },
            });
            setSocket(s);
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerId]);


    const getSelectedVariants = (items) => {
        // Creiamo un oggetto per raggruppare le opzioni selezionate
        const groupedVariants = {};

        items.variants.forEach(variant => {
            const { name, position, options, selected } = variant;

            // Inizializziamo l'array per il nome della variante se non esiste già
            if (!groupedVariants[name]) {
                groupedVariants[name] = {
                    options: [],
                    position: position // Memorizziamo la posizione
                };
            }
            const selectedOptions = options.filter(option => selected.some(sel => sel.id === option.id));
            const selectedOptionNames = selectedOptions.map(option => option.label);
            groupedVariants[name].options.push(...selectedOptionNames); // Aggiungiamo le opzioni selezionate all'array corrispondente

        });

        // Ordiniamo le varianti in base alla posizione
        const sortedVariants = Object.entries(groupedVariants).sort((a, b) => {
            return a[1].position - b[1].position; // Ordina per posizione
        })

        // Ora convertiamo l'oggetto in una stringa formattata
        return sortedVariants.map(([variantName, { options }]) => (
            <Typography key={variantName} style={{ marginBottom: '4px' }}>
                <strong style={{ ...styleVariants }}>{variantName}:</strong>{' '}
                <span style={{ ...styleOptions }}>{options.join(', ')}</span>
            </Typography>
        )); // Utilizziamo Typography per formattare


    };


    const handlePay = () => {
        setLoading(true);
        if (!clientSecret) {
            axios.post('/api/stripe/create-payment-intent', {
                // Puoi passare i dettagli dell'ordine necessari per creare il Payment Intent
                accountId: accountIdPayment,
                totalAmount: calculateTotalWithFees(getSumTotal(), false, paymentOption),
                netAmount: getSumTotal()
                //currency: 'eur',
            }).then(({ data }) => {
                setClientSecret(data.clientSecret);
                setPaymentIntentId(data.paymentIntentId);
                setIsOpenDialogPayment(true); // Apri il dialogo solo dopo aver ottenuto il clientSecret
                setLoading(false);
            }).catch(err => {
                console.error("Errore nel caricamento del clientSecret:", err);
            });
        } else {
            setIsOpenDialogPayment(true);
            setLoading(false);
        }

    }


    const handleOrder = async () => {
        // Disabilita il pulsante
        setLoading(true);

        let isOrarioAvailable = true;

        if (!isOwner && formData.deliveryOption.toLowerCase() === 'consegna') {


            const { data } = await axios.post('/api/user/deliveryOptions', { ownerId, deliveryOption: formData.deliveryOption });

            if (data.result.business) {
                const { openConsegna, closeConsegna, abilitaConsegna } = data.result.business;

                const currentTime = new Date();
                const currentHour = currentTime.getHours();
                const currentMinutes = currentTime.getMinutes() + 5; //20 minuti avanti per dare ossigeno

                let newFutureTimes = [];
                let openingHour, openingMinutes, closingHour, closingMinutes;
                if (abilitaConsegna) {
                    openingHour = Number(openConsegna.split(':')[0]);
                    openingMinutes = Number(openConsegna.split(':')[1]);
                    closingHour = Number(closeConsegna.split(':')[0]);
                    closingMinutes = Number(closeConsegna.split(':')[1]);
                } else {
                    // Nessuna opzione selezionata o opzione non abilitata, non fare nulla
                    setIsNotConsegnaEnabled(true);
                    return;
                }

                calcolaOrariDisponibili(newFutureTimes, openingHour, openingMinutes, closingHour, closingMinutes, currentHour, currentMinutes);
                if (formData.deliveryOption.toLowerCase() === 'consegna') {
                    const { orariRimasti } = filtraOrariConsegne(newFutureTimes, data.result.reservation);

                    const orariSplit = formData.deliveryTime.includes('-') ? formData.deliveryTime.split(' - ') : [formData.deliveryTime];

                    function checkOrariPresence(orariRimasti, orariSplit) {
                        return orariSplit.every(orario => orariRimasti.includes(orario));
                    }

                    const result = checkOrariPresence(orariRimasti, orariSplit);

                    isOrarioAvailable = result;
                }

            }


        }


        if (isOrarioAvailable) {
            axios
                .post("/api/reservation/user/orderDelivery", {
                    ...formData,
                    cart: cart.filter(({ amount }) => amount > 0),
                    ownerId: ownerId,
                    socketId: getSocketId(),
                    isOwner
                })
                .then(({ data }) => {
                    if (data.success) {
                        setSuccessOrder(true);
                        sessionStorage.removeItem("reservationDishes");
                        sessionStorage.removeItem("cart");
                        eraseOrder();
                    }
                })
                .catch(error => {
                    // Gestire eventuali errori qui, se necessario
                    console.error("Errore nel creare l'ordine:", error);
                })
                .finally(() => {
                    // Riabilita il pulsante dopo che la richiesta è stata completata
                    setLoading(false);
                });
        } else {
            setOrarioOccupato(true);
        }


    };


    const handlePayOptionChange = (event) => {
        setPaymentOption(event.target.value);
        getSumTotal();
    };


    const getSumTotal = () => {
        let costoConsegna = 0;

        if (formData.deliveryOption.toLowerCase() === 'consegna')
            costoConsegna = parseFloat(formData.costoConsegna);
        let totale = totalPrice + costoConsegna;
        return totale;

    }


    return (
        <>
            {/* TODO: refactoring di sto codice */}
            <Dialog open={orarioOccupato} disableEscapeKeyDown>
                <DialogTitle>Ops! L'orario scelto è già occupato.</DialogTitle>
                <DialogContent style={{ backgroundColor: '#383838' }}>
                    <DialogContentText>Ti invitiamo a scegliere un nuovo orario disponibile.</DialogContentText>
                </DialogContent>
                <DialogActions style={{ margin: '0 auto' }}>
                    <Button onClick={goBack} size="small" variant='contained' color="primary" autoFocus>
                        scegli un altro orario
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={isNotConsegnaEnabled} disableEscapeKeyDown>
                <DialogTitle>Ops! Il gestore ha appena disabilitato la consegna</DialogTitle>
                <DialogActions style={{ margin: '0 auto' }}>
                    <Button onClick={goToForm} size="small" variant='contained' color="primary" autoFocus>
                        torna indietro
                    </Button>
                </DialogActions>
            </Dialog>

            {successOrder ? (
                <>
                    <Typography variant="h4" style={{ marginTop: '45px', marginBottom: '35px' }}>
                        {isOwner ? (
                            <div className={classes.centeredIcon}>
                                <AssignmentTurnedInIcon color='primary' style={{ fontSize: '90px' }} />
                                <Typography
                                    variant='subtitle1'
                                    color='textSecondary'
                                    style={{ textAlign: 'center', letterSpacing: '1px' }}>
                                    Ordine effettuato con successo!
                                </Typography>
                            </div>
                        ) : (
                            <div className={classes.centeredIcon}>
                                <ForwardToInboxIcon color='primary' style={{ fontSize: '90px' }} />
                                <Typography
                                    variant='subtitle1'
                                    color='textSecondary'
                                    style={{ textAlign: 'center', letterSpacing: '1px' }}>
                                    Abbiamo ricevuto il tuo ordine!
                                    <br /> Ti invieremo un'email con i dettagli appena sarà confermato<br /><br />
                                    Nota: Se non ricevi l'email entro pochi minuti, ti invitiamo a controllare anche la cartella "Spam" o "Posta indesiderata"
                                </Typography>
                            </div>
                        )}
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ minWidth: '300px' }} variant="contained" color="primary" onClick={closeDialogForm}>Chiudi</Button>
                    </div>
                </>
            ) : (
                <div className={classes.root}>
                    <div className={classes.summary} style={{ border: '1px solid #cccc', borderRadius: '20px', padding: '20px' }}>
                        <Typography variant="h4" gutterBottom>{formData.deliveryOption}</Typography>
                        <Divider style={{ margin: '15px 0', backgroundColor: '#fff' }} />
                        <Typography variant="body1" className={classes.fontWeight}>Orario: </Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.deliveryTime} </Typography>
                        <Typography variant="body1" className={classes.fontWeight}>{formData.surname ? 'Nome e cognome:' : 'Nome:'}</Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.name} {formData.surname}</Typography>
                        <Typography variant="body1" className={classes.fontWeight}>Telefono: </Typography>
                        <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.phoneNumber}</Typography>
                        {formData.address && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight}>Indirizzo:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic}>{formData.address}</Typography>
                            </>
                        )}
                        {formData.city && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Comune:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.city}</Typography>
                            </>
                        )}

                        {formData.email && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Email:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.email}</Typography>
                            </>
                        )}

                        {formData.noteDelivery && (
                            <>
                                <Typography variant="body1" className={classes.fontWeight} >Note di consegna:</Typography>
                                <Typography variant="body1" gutterBottom className={classes.fontItalic} >{formData.noteDelivery}</Typography>
                            </>
                        )}
                    </div>

                    <div className={classes.orderItems}>
                        {cart.map((item, index) => (
                            <div key={index} className={classes.orderItem}>

                                <Grid container style={item.variants && item.variants.length ? { marginBottom: '10px' } : {}}>
                                    <Grid item xs={1}>
                                        <Badge overlap='rectangular' color='primary' badgeContent={`x${item.amount}`}></Badge>
                                    </Grid>
                                    <Grid item xs={8} >
                                        <Typography variant="body1" >{item.name}</Typography>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Typography variant="body1" >{(item.amount * getDishPrice(item)).toFixed(2).toString().replace('.', ',')} €</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container style={item.note ? { marginBottom: '10px' } : {}}>
                                    <Grid item xs={12}>
                                        {item.variants && item.variants.length !== 0 && (
                                            <div> {getSelectedVariants(item)}</div>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {item.note && (<Typography variant="body1" ><strong>Note:</strong> <em>{item.note}</em></Typography>)}
                                    </Grid>
                                </Grid>

                                <Divider style={{ margin: '15px 0', backgroundColor: '#fff' }} />
                            </div>
                        ))}
                    </div>

                    {accountIdPayment ? (
                        <OrderPayment
                            formData={formData}
                            totalPrice={totalPrice}
                            paymentOption={paymentOption}
                            isOwner={isOwner}
                            getSumTotal={getSumTotal}
                            handlePayOptionChange={handlePayOptionChange}
                            goBack={goBack}
                            handleOrder={handleOrder}
                            handlePay={handlePay}
                            loading={loading}
                        />
                    ) : (
                        <OrderNotPayment
                            formData={formData}
                            totalPrice={totalPrice}
                            getSumTotal={getSumTotal}
                            goBack={goBack}
                            handleOrder={handleOrder}
                            loading={loading}
                        />
                    )}


                </div>

            )}

            {clientSecret && isOwner === false && (
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                    <DialogPaymentStripe
                        setIsOpenDialogPayment={setIsOpenDialogPayment}
                        isOpenDialogPayment={isOpenDialogPayment}
                        formData={formData}
                        cart={cart}
                        ownerId={ownerId}
                        getSocketId={getSocketId}
                        isOwner={isOwner}
                        eraseOrder={eraseOrder}
                        paymentIntentId={paymentIntentId}
                    />
                </Elements>
            )}

        </>
    );
}

export default OrderSummary;
