import { Grid, IconButton, ListItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import AlertCollapse from '../../customComponents/alert.collapse';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(10),
		display: 'flex',
		flexDirection: 'row',
		'&:after': {
			content: '" "',
			display: 'block',
			flex: 1,
		},
	},
	containerChildLeft: {
		flex: 1,
		textAlign: 'left',
	},
	containerChildRight: {
		flex: 1,
		textAlign: 'center',
		alignSelf: 'center',
	},
	fullWidthList: { width: '100%' },
	dashedDivider: { borderBottom: `1px dashed ${theme.palette.divider}` },
}));

export default function RoomDialogItem({
	id,
	i,
	rooms,
	name,
	setRooms,
	setCurrentRoom,
	currentRoom,
}) {
	const classes = useStyles();

	const [isEditing, setIsEditing] = useState(false);
	const [error, setError] = useState(false);
	const nameRef = useRef();

	const startEditing = () => setIsEditing(true);

	const editRoom = () => {
		const name = nameRef.current.value;
		if (name && name.length < 100) {
			setError(false);
			axios.post('/api/rooms/owner/edit', { id, name: name }).then(({ data }) => {
				if (!data || !data.success) {
					setError(true);
				} else {
					const r = [...rooms];
					let i = rooms.findIndex(({ id: curId }) => id === curId);
					if (i !== -1) {
						r[i].name = name;
						setRooms(r);
						setIsEditing(false);
					}
				}
			});
		} else {
			setError(true);
		}
	};

	const deleteRoom = () => {
		axios.post('/api/rooms/owner/delete', { id }).then(({ data }) => {
			if (!data || !data.success) {
				setError(data.error);
			} else {
				let r = [...rooms];
				r = r.filter((room) => room.id !== id);
				setRooms(r);
				if (currentRoom === id) {
					setCurrentRoom(r[0].id);
				}
			}
		});
	};

	return (
		<ListItem key={id} className={i !== rooms.length - 1 ? classes.dashedDivider : ''}>
			<Grid container alignItems='center'>
				<Grid item xs={12}>
					<AlertCollapse
						show={error}
						text={
							error === 'ROOM_NOT_EMPTY'
								? 'Impossibile eliminare una sala contenente tavoli'
								: 'Nome vuoto o troppo lungo'
						}
					/>
				</Grid>

				<Grid item xs={9}>
					{isEditing ? (
						<TextField defaultValue={name} inputRef={nameRef} fullWidth />
					) : (
						name
					)}
				</Grid>
				<Grid item xs={2}>
					<IconButton size='small' onClick={isEditing ? editRoom : startEditing}>
						{isEditing ? <CheckIcon /> : <EditIcon />}
					</IconButton>
				</Grid>
				<Grid item xs={1}>
					<IconButton size='small' onClick={deleteRoom}>
						<ClearIcon color='secondary' />
					</IconButton>
				</Grid>
			</Grid>
		</ListItem>
	);
}
