import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import socketIOClient from "socket.io-client";
import { UserContext } from "./user.context";
import notificationSoundFile from "../../../client/src/notification/Melodico.mp3";
import notificationSoundNewOrder from "../../../client/src/notification/Tesoro.mp3";
import { filterForPrintedAndSelectedOption } from "../functions";

const useDashboard = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [connected, setConnected] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    text: "",
    severity: "",
    timeout: null,
    isfirstConnectionDone: false,
    noCloseButton: true,
  });
  const [lastGeneralUpdate, setLastGeneralUpdate] = useState(new Date()); //last time tables were fatched
  const [ringingBell, setRingingBell] = useState(false);
  const [deliveryCount, setDeliveryCount] = useState(0);
  const [notifiDelivery, setNotifiDelivery] = useState(false);
  let filter = localStorage.getItem('filterRes');


  //reset state every path change
  useEffect(() => {
    const unlisten = history.listen(() => {
      setData([]);
    });
    return () => unlisten();
  });

  //onload: fetch data from server on every path change
  useEffect(() => {
    let subscribed = true; // Dichiarato come variabile locale

    if (connected) {
      setIsLoading(true);
      axios.post(`/api/dashboard/owner/get`).then(({ data }) => {
        if (subscribed) {
          if (!data || !data.success) {
            setIsLoading(false);
            setError(data.error || true);
          } else {
            data.reservations.forEach(({ tableId, isCalled }) => {
              if (isCalled) {
                callTable({ tableId, currentData: data.reservations });
              }
            });
            setIsLoading(false);
            setData(data.reservations);
            setLastGeneralUpdate(new Date());
          }
        }
      });
    }

    // Cleanup function to unsubscribe
    return () => {
      subscribed = false; // Aggiorna la variabile locale
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected, history.location.pathname]);

  //connect to socket
  useEffect(() => {
    //let isMounted = true;

    // Connect to socket when user is available
    if (user && user.id) {
      const s = socketIOClient(process.env.REACT_APP_SERVER_URL, {
        secure: true,
        transports: ["websocket"],
        query: { ownerId: user.id },
      });
      setSocket(s);
    }

    // Cleanup function to disconnect socket
    return () => {
      //isMounted = false;
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //manage real time events
  useEffect(() => {
    let isMounted = true;

    if (socket) {
      socket.on("connect", () => {
        if (isMounted) {
          setConnected(true);
        }
      });
      socket.on("disconnect", () => {
        if (isMounted) {
          setConnected(false);
        }
      });
      socket.on("updateCountDelivery", updateCountDelivery);
      socket.on("updateTable", updateTable);
      socket.on("changeStatusPay", changeStatusPay);
      socket.on("removeTable", removeTable);
      socket.on("calledService", callTable);
      socket.on("discardNotification", discardNotification);
      socket.on("modifyTable", modifyTable);
      socket.on("removeDelivery", removeDelivery);

      return () => {
        isMounted = false; // Imposta isMounted a false per indicare che il componente è smontato
        socket.off("connect");
        socket.off("disconnect");
        socket.off("updateCountDelivery", updateCountDelivery);
        socket.off("updateTable", updateTable);
        socket.off("changeStatusPay", changeStatusPay);
        socket.off("removeTable", removeTable);
        socket.off("calledService", callTable);
        socket.off("discardNotification", discardNotification);
        socket.off("modifyTable", modifyTable);
        socket.off("removeDelivery", removeDelivery);
      };
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, data, isLoading]);

  //manage connection lost notification
  useEffect(() => {
    if (!notification.isfirstConnectionDone) {
      if (connected) {
        setNotification({ ...notification, isfirstConnectionDone: true });
      }
      return;
    }
    if (!connected) {
      setNotification({
        ...notification,
        text: "Connessione persa, tentativo di riconnessione...",
        severity: "warning",
        open: true,
        timeout: null,
      });
    } else {
      setNotification({
        ...notification,
        text: "Connessione ristabilita.",
        severity: "success",
        open: true,
        timeout: undefined,
      });
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  const getSocketId = () => (socket ? socket.id : null);

  const getTable = (tableId) => {
    let d = [...data];
    const myTableIndex = d.map((res) => (res.tableId || res.deliveryId)).indexOf(tableId);
    if (myTableIndex !== -1) {
      let myTable = d[myTableIndex];
      return [myTable, myTableIndex];
    }
    return [null, -1];
  };

  const _resToggleFilter = (res) => {
    setData(res.data.reservations);
  }

  const setTable = (tableId, value) => {
    let d = [...data];
    const [_, myTableIndex] = getTable(tableId);
    if (myTableIndex !== -1) {
      d[myTableIndex] = value; // Oggetto {MyTable, }
      d[myTableIndex].accettato = d[myTableIndex].reservations.length > 0 ? d[myTableIndex].reservations[0].accettato : null
      setData(d);
      setLastGeneralUpdate(new Date());
    }
  };

  const getDish = (tableId, dishId) => {
    let [myTable] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let myDish = myTable.reservations[myDishIndex];
        return [myDish, myDishIndex];
      }
    }

    return [null, -1];
  };

  const setDish = (tableId, dishId, value) => {
    let d = [...data];
    let [myTable, myTableIndex] = getTable(tableId);
    let [_, myDishIndex] = getDish(tableId, dishId);
    myTable.reservations[myDishIndex] = value;
    d[myTableIndex] = myTable;
    setData(d);
    setLastGeneralUpdate(new Date());
  };

  const deleteDish = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "owner_deleted";
        setTable(tableId, { ...myTable, reservations: r });
      }
    }
  };

  const restoreDish = (tableId, dishId) => {
    let [myTable, _] = getTable(tableId);
    if (myTable) {
      const myDishIndex = myTable.reservations
        .map(({ id }) => id)
        .indexOf(dishId);
      if (myDishIndex !== -1) {
        let r = [...myTable.reservations];
        r[myDishIndex].status = "preparation";

        setTable(tableId, { ...myTable, reservations: r });
      }
    }
  };
  const callTable = ({ tableId, currentData }) => {
    currentData = currentData ?? data;

    const i = currentData.findIndex(({ tableId: t }) => t === tableId);
    if (i !== -1) {
      let d = [...currentData];
      d[i].isCalled = true;
      setData(d);
      setRingingBell(true);
      if (history.location.pathname !== '/owner/report') {
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play().catch(err => {
          console.log('Errore notifica suono ' + err
          )
        });
      }
    } else {
      let d = [
        ...currentData,
        { tableId, isCalled: true, reservations: [], isLoading: false },
      ];
      setData(d);
      setRingingBell(true);
      if (history.location.pathname !== '/owner/report') {
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play().catch(err => {
          console.log('Errore notifica suono ' + err
          )
        });
      }
    }
  };


  /*
   La funzione getNewReservation serve per avere un nuovo tavolo perché la funzione getTable può restituire "data" ma con zero reservations 
   Esempio
    [
      {
          "tableId": "1",
          "isModifing": false,
          "reservations": [],
          "isLoading": false
      }
    ]
   creo la funzione per avere un campo di appoggio per farlo suonare
   Modificando getTable si rompe la disposizione dei tavoli 
  */
  const getNewReservation = (tableId) => {
    let dataFilter = filterForPrintedAndSelectedOption(data);
    let x = [...dataFilter];
    let d = x.filter(el => el.reservations.length !== 0);
    const newTableIndex = d.map((res) => (res.tableId || res.deliveryId)).indexOf(tableId);
    if (newTableIndex !== -1) {
      return newTableIndex;
    }
    return -1;
  };

  const updateTable = (tableId) => {
    let [myTable, myTableIndex] = getTable(tableId);
    let newTableIndex = getNewReservation(tableId);
    if (myTableIndex !== -1) {
      //table already exists -> set loading
      setTable(tableId, { ...myTable, isLoading: true });
    }

    axios
      .post(`/api/dashboard/owner/table/get`, { tableId, socketId: getSocketId(), filter: localStorage.getItem('filterRes') })
      .then(({ data: result }) => {
        if (!result || !result.success) {
          //error
          setError(result.error || true);
        } else {
          //success
          if (myTableIndex !== -1) {

            //table already exists -> update
            setTable(tableId, {
              ...myTable,
              reservations: result.reservations,
              isLoading: false,
              isModifing: false,
            });
            const filteredReservations = result.reservations.filter(reservation => {
              if (reservation.printed !== 0) return false;
              if (reservation.accettato === 0) return false;
              if (filter === 'tutto') return reservation.selected_option === 'cucina' || reservation.selected_option === 'bar' || reservation.selected_option === 'fritti';
              return reservation.selected_option === filter;
            });

            if (newTableIndex === -1 && filteredReservations.length !== 0 && history.location.pathname === '/owner/dashboard') {
              const newOrderSound = new Audio(notificationSoundNewOrder);
              newOrderSound.play().catch(err => {
                console.log('Errore notifica suono ' + err
                )
              });
            }
          } else {
            //table doesnt exists -> add new
            addTable(tableId, result);
            const filteredReservations = result.reservations.filter(reservation => {
              if (reservation.printed !== 0) return false;
              if (reservation.accettato === 0) return false;
              if (filter === 'tutto') return reservation.selected_option === 'cucina' || reservation.selected_option === 'bar' || reservation.selected_option === 'fritti';
              return reservation.selected_option === filter;
            });
            if (filteredReservations.length > 0 && history.location.pathname === '/owner/dashboard') {
              const newOrderSound = new Audio(notificationSoundNewOrder);
              newOrderSound.play().catch(err => {
                console.log('Errore notifica suono ' + err
                )
              });
            }
          }
          setLastGeneralUpdate(new Date());
        }
      });
  };

  const updateCountDelivery = () => {
    axios.get('/api/delivery/getCountDelivery').then(res => {
      setDeliveryCount(res.data.result)
      setNotifiDelivery(true);
      if (history.location.pathname !== '/owner/report') {
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play().catch(err => {
          console.log('Errore notifica suono ' + err
          )
        });
      }
    })
  };

  const handleAcceptOrder = (deliveryId) => {
    // CHIAMATA PER LA PARTENZA DELLE MAIL
    //window.print();
    axios.post('/api/delivery/deliveryAccept', { deliveryId, socketId: getSocketId(), }).then((res) => {
      if (res.data.success) {
        setDeliveryCount(prevCount => prevCount - 1);
      }
    })
  };


  const handleRejectOrder = (deliveryId) => {
    axios.post('/api/delivery/deliveryReject', { deliveryId, socketId: getSocketId(), }).then((res) => {
      if (res.data.success) {
        setDeliveryCount(prevCount => prevCount - 1);
      }
    })
  };

  const removeDelivery = (deliveryId) => {
    const _copyData = data.filter(el => el.deliveryId !== deliveryId);
    setData(_copyData);
  }


  const handleChangeStatusPay = (id, status) => {
    axios.post('/api/delivery/changeStatusPay', { deliveryId: id, status, socketId: getSocketId(), }).then((res) => {
      const _copyData = structuredClone(data);
      _copyData.forEach(el => {
        if (el.deliveryId === id) el.paymentIntentId = status;
      });
      setData(_copyData);
    }).catch((err) => {
      console.log('ERRORE CATCH changeStatusPay: ' + err);
    })

  };


  const changeStatusPay = (id, status) => {
    const _copyData = structuredClone(data);
    _copyData.forEach(el => {
      if (el.deliveryId === id) el.paymentIntentId = status;
    });
    setData(_copyData);
  }


  const modifyTable = (tableId, value) => {
    //TODO è undefined non va in errore perche in reservation c'è tutto
    //console.log('modifyTable tableId', tableId) 
    axios
      .post("/api/dashboard/owner/table/modify", {
        reservations: value.reservations,
        tableId,
        socketId: getSocketId(),
      })
      .then(({ data }) => {
        if (!data || !data.success) {
          //error
          setError(data.error || true);
        } else {
          //success
          setTable(tableId, value);
        }
      });
  };

  // Questo loading viene gestito nel componente owner.dashboard.table.card.jsx r. 52
  const printTable = (tableId, loading, componentPrint) => {
    axios
      .post("/api/dashboard/owner/table/print", {
        tableId,
        selected_option: localStorage.getItem('filterRes'),
        socketId: getSocketId(),
      })
      .then(({ data }) => {
        if (!data || !data.success) {
          //error
          setError(data.error || true);
        } else {
          removeTable(tableId, true, loading, componentPrint);
        }
      });
  };



  const addTable = (tableId, result) => {
    let d = [...data];
    if (result.reservations.length) {
      const newJson = {
        accettato: result.reservations[0].accettato,
        paymentIntentId: result.reservations[0].paymentIntentId,
        cognome: result.reservations[0].cognome,
        comune: result.reservations[0].comune,
        noteConsegna: result.reservations[0].noteConsegna,
        deliveryId: result.reservations[0].deliveryId,
        indirizzo: result.reservations[0].indirizzo,
        isModifing: false,
        nome: result.reservations[0].nome,
        orario: result.reservations[0].orario,
        tableId: result.reservations[0].tableId,
        telefono: result.reservations[0].telefono,
        tipo: result.reservations[0].tipo,
        reservations: result.reservations,
      }
      d.push({ ...newJson });
      setData(d);
      setLastGeneralUpdate(new Date());
    }

  };

  const removeTable = (tableId, noDbUpdate, loading, componentPrint, isDelivery) => {
    const myTableIndex = data.map((res) => (res.tableId || res.deliveryId)).indexOf(tableId);
    // const filterRes = localStorage.getItem('filterRes');
    if (myTableIndex !== -1) {
      let d = [...data];
      if (!noDbUpdate) {
        axios
          .post("/api/dashboard/owner/table/delete", {
            isDelivery,
            tableId,
            socketId: getSocketId(),
          })
          .then(({ data }) => {
            if (!data || !data.success) {
              //error
              setError(data.error || true);
            } else {
              //success
              d.splice(myTableIndex, 1);
              discardCalledTable(tableId)
              setData(d);
              setLastGeneralUpdate(new Date());
            }
          });
      } else {
        setTimeout(() => {
          axios.post(`/api/dashboard/owner/get`, { filter: localStorage.getItem('filterRes') }).then((res) => {
            if (componentPrint) {
              loading(false); // Questi loading vengono gestiti nel componente owner.dashboard.table.card.jsx r.52
            }
            setData(res.data.reservations);
          });
          //}
        }, 1000)
        //setData(d);
        setLastGeneralUpdate(new Date());
      }
    }

  };

  const switchDishStatus = (tableId, dishId) => {
    let [myDish] = getDish(tableId, dishId);
    setDish(tableId, dishId, { ...myDish, loadingStatus: true }); //set loading:true to show loader

    if (myDish) {
      switch (myDish.status) {
        case "preparation":
          myDish.status = "ready";
          break;
        case "ready":
          myDish.status = "completed";
          break;
        case "completed":
          myDish.status = "preparation";
          break;
        default:
          break;
      }
      myDish.loadingStatus = false; //set loading:false to hide loader
      axios
        .post("/api/dashboard/owner/status/set", {
          id: myDish.id,
          status: myDish.status,
          socketId: getSocketId(),
        })
        .then(({ data }) => {
          if (!data || !data.success) {
            //error
            setError(data.error || true);
          } else {
            //success
            setDish(tableId, dishId, myDish);
          }
        });
    }
  };

  const discardNotification = ({ tableId }) => {
    let d = [...data];
    let i = d.findIndex(({ tableId: id }) => id === tableId);
    if (i !== -1) {
      d[i].isCalled = false;
    }
    setData(d);
  };

  const discardCalledTable = (tableId) => {
    socket.emit("discardCalledTable", { tableId, ownerId: user.id });
  };

  return {
    data,
    setData,
    socket,
    setSocket,
    isLoading,
    setIsLoading,
    error,
    setError,
    connected,
    setConnected,
    notification,
    setNotification,
    getSocketId,
    _resToggleFilter,
    getTable,
    setTable,
    getDish,
    setDish,
    modifyTable,
    addTable,
    removeTable,
    switchDishStatus,
    deleteDish,
    restoreDish,
    lastGeneralUpdate,
    ringingBell,
    setRingingBell,
    discardCalledTable,
    printTable,
    updateTable,
    handleAcceptOrder,
    handleRejectOrder,
    setDeliveryCount,
    deliveryCount,
    setNotifiDelivery,
    notifiDelivery,
    removeDelivery,
    handleChangeStatusPay,
    changeStatusPay
  };
};

export const DashboardContext = createContext();

export const DashboardProvider = ({ ...rest }) => {
  const dashboard = useDashboard();
  return (
    <>
      <DashboardContext.Provider value={dashboard}>
        {rest.children}
      </DashboardContext.Provider>
    </>
  );
};
