import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
	container: {
		overflowY: 'scroll',
		overflowX: 'scroll',
		position: 'relative',
	},
	resizer: {
		position: 'relative',
		top: ({ size }) => size,
		left: ({ size }) => size,
		width: 10,
		height: 10,
		visibility: 'none',
	},
}));

const DraggableContainer = React.forwardRef(({ size, children, className }, ref) => {
	const classes = useStyles({ size });

	return (
		<div
			id='container-draggable'
			className={`${className} ${classes.container}`}
			ref={ref}
			onDragOver={(e) => {
				e.preventDefault();
			}}
			onDragEnter={(e) => {
				e.preventDefault();
			}}
		>
			{React.Children.map(children, (child) =>
				React.isValidElement(child) ? React.cloneElement(child) : child
			)}
			{/* the purpose of this div is to resize the inner space of the container div */}
			<div className={classes.resizer}></div>
		</div>
	);
});

export default DraggableContainer;
