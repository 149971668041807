import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	spacingTop: {
		marginTop: theme.spacing(6),
	},
}));

export default function Notification({ setStatus, status, bottom, ...rest }) {
	const classes = useStyles();

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setStatus({ ...status, open: false });
	};

	return (
		<Snackbar
			open={status.open}
			autoHideDuration={status.timeout === undefined ? 2000 : status.timeout}
			onClose={handleClose}
			anchorOrigin={{ vertical: bottom ? 'bottom' : 'top', horizontal: 'center' }}
			className={status.noMargin || classes.spacingTop}
			action={<></>}
			{...rest}
		>
			<Alert onClose={!status.noCloseButton ? handleClose : null} severity={status.severity}>
				{status.text}
			</Alert>
		</Snackbar>
	);
}
