import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useRef, useState, useContext, createContext, } from "react";
import ConfirmDialog from "../../customComponents/confirm.dialog";
import Draggable from "../../customComponents/draggable";
import RoomDialogView from "./owner.room.dialog.view";
import DialogMenu from "./owner.room.dialog.menu";
import DialogMenuMobile from "./owner.room.dialog.menu.mobile";
import { DashboardContext } from "../../../contexts/dashboard.context";
import { ReservationContext } from "../../../contexts/reservation.context";
import axios from 'axios';
import "./printBill.css";

const useStyles = makeStyles((theme) => ({
  square: {
    background: theme.palette.background.paper,
    border: ({ isLocked, status }) =>
      `8px ${isLocked ? "solid" : "dashed"} ${status ? 'red' : 'green'}`,
    borderRadius: theme.shape.borderRadius,
    height: ({ squareSize }) => squareSize,
    width: ({ squareSize }) => squareSize,
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    fontSize: 20,
    userSelect: "none",
    color:'#fff'
  },
  squareButton: {
    padding: "0 !important",
  },
}));

export const OwnerRoomTableContext = createContext();

export default function OwnerRoomTable({
  canvasRef,
  table,
  setCoords,
  isLocked,
  switchTableStatus,
  deleteTable,
  multiTable
}) {
  const squareSize = 50;
  const classes = useStyles({ squareSize, isLocked, status: table.status });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [openDialogClose, setOpenDialogClose] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogView, setOpenDialogView] = useState(false);
  const isMobile = useMediaQuery("(max-width:576px)");
  const { data } = useContext(DashboardContext);
  const { eraseOrder } = useContext(ReservationContext);
  const [openDialogMenu, setOpenDialogMenu] = useState(false);
  const [openDialogMenuMobile, setOpenDialogMenuMobile] = useState(false);
  const [dataMenuObj, setDataMenuObj] = useState({ dataMenu: [], categories: [] })

  // Modifico l'array originale table e ci setto lo status a 1 se ha piatti
  for (let y = 0; y < multiTable.length; y++) {
    multiTable[y].status = 0;
    for (let x = 0; x < data.length; x++) {
      if (data[x].tableId === multiTable[y].id) {
        multiTable[y].status = 1;
      }
    }
  }

  const handleOpenMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleClickOutMenu(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const updateCoords = (e) => {
    setCoords(table.id, e.coords.x, e.coords.y, table.businessId);
  };

  /*   const handleClickChangeStatus = (e) => {
    if (table.status) {
      setOpenDialogClose(true);
    } else {
      handleChangeStatus(true);
    }
    handleCloseMenu(e);
  }; */

  const handleChangeStatus = (result) => {
    if (result) {
      switchTableStatus(table.id, table.businessId);
    }
    setOpenDialogClose(false);
  };

  const handleClickDelete = (e) => {
    setOpenDialogDelete(true);
    handleCloseMenu(e);
  };

  const handleDelete = (result) => {
    if (result) {
      deleteTable(table.id, table.businessId);
    }
    setOpenDialogDelete(false);
  };

  const handleClickView = () => {
    setOpenDialogView(true);
  };

  const getCategory = (dishes) => {
    const c = [];
    dishes.forEach(({ categoryId, categoryName }) => {
      const catId = c.map(({ id }) => id);
      if (catId.indexOf(categoryId) === -1) {
        c.push({
          id: categoryId,
          name: categoryName,
        });
      }
    });
    return c
  }

  const handleClickOrder = () => {
    // FAI GIRARE (forse)
    if (!isMobile) {
      if (!openDialogMenu) {
        axios.post('/api/menu/owner/order/get', { businessId: table.businessId }).then(res => {
          let categories = getCategory(res.data.data);
          setDataMenuObj({ dataMenu: res.data.data, categories });

        })
      } else {
        sessionStorage.removeItem('cart');
        eraseOrder();
      }
      setOpenDialogMenu(!openDialogMenu);
    } else {
      if (!openDialogMenuMobile) {
        axios.post('/api/menu/owner/order/get', { businessId: table.businessId }).then(res => {
          let categories = getCategory(res.data.data);
          setDataMenuObj({ dataMenu: res.data.data, categories });

        })
      } else {
        sessionStorage.removeItem('cart');
        eraseOrder();
      }
      setOpenDialogMenuMobile(!openDialogMenuMobile);
    }


  }

  return (
    <>
      <OwnerRoomTableContext.Provider value={{ tableId: table.id, ownerId: table.businessId }}>
        {!isMobile && (<DialogMenu tableId={table.id} openDialog={openDialogMenu} setOpenDialog={setOpenDialogMenu} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} />)}
        {isMobile && (<DialogMenuMobile table={table} tableId={table.id} openDialog={openDialogMenuMobile} setOpenDialog={setOpenDialogMenuMobile} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} />)}
      </OwnerRoomTableContext.Provider>

      {!isMobile ? (
        <Draggable
          containerRef={canvasRef}
          onDragEnd={updateCoords}
          startingCoords={{ x: table.x, y: table.y }}
          disableDrag={isLocked}
        >
          {isLocked ? (
            <div>
              <Button
                className={classes.squareButton}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleOpenMenu}
              >
                <div className={classes.square}>{table.id}</div>
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 10 }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleCloseMenu}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleClickOutMenu}
                        >
                          {/*  <MenuItem onClick={handleClickChangeStatus}>
                          {table.status ? "Chiudi" : "Apri"}
                        </MenuItem> */}
                          <MenuItem onClick={handleClickView}>Visualizza</MenuItem>
                          <MenuItem onClick={handleClickOrder}>Ordina</MenuItem>
                          {!table.status && (
                            <MenuItem onClick={handleClickDelete}>Elimina</MenuItem>
                          )}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <ConfirmDialog
                text={{
                  text: "Conferma chiusura tavolo",
                  description: `Sei sicuro di voler chiudere il tavolo ${table.id}?`,
                }}
                handleClose={handleChangeStatus}
                open={openDialogClose}
              />
              <ConfirmDialog
                text={{
                  text: "Conferma eliminazione tavolo",
                  description: `Sei sicuro di voler eliminare il tavolo ${table.id}?`,
                }}
                handleClose={handleDelete}
                open={openDialogDelete}
              />
              <OwnerRoomTableContext.Provider value={{ tableId: table.id, ownerId: table.businessId }}>
                <RoomDialogView
                  openDialogView={openDialogView}
                  setOpenDialogView={setOpenDialogView}
                  tableId={table.id}
                  businessId={table.businessId}
                />
              </OwnerRoomTableContext.Provider>

            </div>
          ) : (
            <div className={classes.square}>{table.id}</div>
          )}
        </Draggable>
      ) : (
        <>
          <Button
            className={classes.squareButton}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <div className={classes.square}>{table.id}</div>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleClickOutMenu}
                    >
                      {/* <MenuItem onClick={handleClickChangeStatus}>
                      {table.status ? "Chiudi" : "Apri"}
                    </MenuItem> */}
                      <MenuItem onClick={handleClickView}>Visualizza</MenuItem>
                      <MenuItem onClick={handleClickOrder}>Ordina</MenuItem>
                      {!table.status && (
                        <MenuItem onClick={handleClickDelete}>Elimina</MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <ConfirmDialog
            text={{
              text: "Conferma chiusura tavolo",
              description: `Sei sicuro di voler chiudere il tavolo ${table.id}?`,
            }}
            handleClose={handleChangeStatus}
            open={openDialogClose}
          />
          <ConfirmDialog
            text={{
              text: "Conferma eliminazione tavolo",
              description: `Sei sicuro di voler eliminare il tavolo ${table.id}?`,
            }}
            handleClose={handleDelete}
            open={openDialogDelete}
          />
          <OwnerRoomTableContext.Provider value={{ tableId: table.id, ownerId: table.businessId }}>
            <RoomDialogView
              openDialogView={openDialogView}
              setOpenDialogView={setOpenDialogView}
              tableId={table.id}
              businessId={table.businessId}
            />
          </OwnerRoomTableContext.Provider>


        </>)}

    </>);

}
