import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	List,
	ListItem,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import React, { useState } from 'react';
import AlertCollapse from '../../customComponents/alert.collapse';
import RoomDialogItem from './owner.room.dialog.item';

const useStyles = makeStyles((theme) => ({
	fullWidthList: { width: '100%' },
}));

export default function OwnerRoomDialog({
	rooms,
	setRooms,
	open,
	setOpen,
	setCurrentRoom,
	currentRoom,
}) {
	const classes = useStyles();
	const [error, setError] = useState(null);
	const [name, setName] = useState('');

	const createRoom = () => {
		if (name && name.length < 100) {
			setError(false);
			axios.post('/api/rooms/owner/add', { name: name }).then(({ data }) => {
				if (!data || !data.success) {
					setError(true);
				} else {
					setRooms([...rooms, { id: data.id, name }]);
					setName('');
					handleClose();
				}
			});
		}
	};

	const handleClose = () => {
		setOpen(false);
		setError(false);
	};

	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
			<DialogTitle>Gestisci sale</DialogTitle>
			<DialogContent>
				<AlertCollapse text={'Si è verificato un errore'} show={error} />
				<List>
					<ListItem>
						<Grid container alignItems='center' spacing={1}>
							<Grid item xs={12}>
								<List className={classes.fullWidthList}>
									{rooms &&
										rooms.map(({ id, name }, i) => (
											<RoomDialogItem
												key={id}
												id={id}
												i={i}
												rooms={rooms}
												name={name}
												setRooms={setRooms}
												setCurrentRoom={setCurrentRoom}
												currentRoom={currentRoom}
											/>
										))}
								</List>
							</Grid>
						</Grid>
					</ListItem>
					<ListItem>
						<Grid container alignItems='center'>
							<Grid item xs={11}>
								<TextField
									autoFocus
									margin='dense'
									label='Nome nuova sala'
									fullWidth
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</Grid>
							<Grid item xs={1}>
								<IconButton onClick={createRoom}>
									<AddIcon />
								</IconButton>
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					Chiudi
				</Button>
			</DialogActions>
		</Dialog>
	);
}
