import React, { useState, useContext } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardContext } from "../../../contexts/dashboard.context";
import { ReservationContext } from "../../../contexts/reservation.context";
import { getDisplayDate, getMaxDateOfArray, toDate, formatVariants } from "../../../functions";
import DialogMenuMobile from "./owner.room.dialog.menu.mobile"
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import {
    AppBar,
    Button,
    Grid,
    Toolbar,
    TextField
} from "@mui/material";


function RoomViewMobile({ tableId, handleClose, table, classes, businessId, handleCloseDialogReject, handleCloseDialogAccept }) {
    const [isEditing, setIsEditing] = useState(false);
    const [dataMenuObj, setDataMenuObj] = useState({ dataMenu: [], categories: [] })
    const [openDialogMenuMobile, setOpenDialogMenuMobile] = useState(false);
    const { deleteDish, restoreDish, setDish, getDish, modifyTable } = useContext(DashboardContext);
    const { eraseOrder } = useContext(ReservationContext);
    let tableObj, totalPrice, i = -1
    if (table !== null) {
        i = table.reservations.findIndex((res) => (res.tableId || res.deliveryId) === tableId);
        tableObj = i === -1 ? null : table.reservations[i];
        totalPrice =
            i === -1
                ? null
                : table.reservations.reduce(
                    (acc, { price, amount, status }) => {
                        if (status !== "owner_deleted") {
                            return acc + price * amount;
                        } else {
                            return acc;
                        }
                    },
                    0
                );
    }


    const handleClickEdit = () => {
        modifyTable(tableObj.id, table);
        setIsEditing(!isEditing);
    };

    const handleChangeDish = (dishId, value) => {
        const [myDish] = getDish(tableId, dishId);
        setDish(tableId, dishId, { ...myDish, ...value });
    };


    const lastUpdate =
        i === -1
            ? null
            : getMaxDateOfArray(
                table.reservations.map(({ lastUpdate }) => toDate(lastUpdate))
            );

    const getCategory = (dishes) => {
        const c = [];
        dishes.forEach(({ categoryId, categoryName }) => {
            const catId = c.map(({ id }) => id);
            if (catId.indexOf(categoryId) === -1) {
                c.push({
                    id: categoryId,
                    name: categoryName,
                });
            }
        });
        return c
    }


    const handleClickOrder = () => {
        // FAI GIRARE (forse)
        if (!openDialogMenuMobile) {
            axios.post('/api/menu/owner/order/get', { businessId }).then(res => {
                let categories = getCategory(res.data.data);
                setDataMenuObj({ dataMenu: res.data.data, categories });
            })
        } else {
            sessionStorage.removeItem('cart');
            eraseOrder();
        }
        setOpenDialogMenuMobile(!openDialogMenuMobile);
    }

    return (
        <div id='roomViewMobile' style={{ overflow: 'scrollable' }}>
            <AppBar style={{ display: 'block' }}>
                <Toolbar >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">{table ? (table?.tableId ? `Tavolo ${table.tableId}` : table?.tipo + ' - ' + table?.nome + ' ' + table?.cognome) : 'Tavolo ' + tableId}</Typography>
                </Toolbar>
            </AppBar>

            <DialogMenuMobile table={table} tableId={tableId} openDialog={openDialogMenuMobile} setOpenDialog={setOpenDialogMenuMobile} handleClickOrder={handleClickOrder} dataMenuObj={dataMenuObj} ownerId={businessId} />

            {table !== null ? (
                <Card style={{ marginTop: '20px', boxShadow: 'none', backgroundColor: 'rgb(30, 30, 30, .4)' }}>
                    <CardContent >
                        <Grid container style={{ marginBottom: '10px' }}>
                            <Grid item xs={12} >
                                <Typography
                                    gutterBottom
                                    variant="body2"
                                    component="h2"
                                    color="textSecondary"
                                >
                                    Ultima ordinazione: {getDisplayDate(lastUpdate)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {table?.reservations.map(el => (
                            <div key={el.id} style={{ borderBottom: '1px', borderBottomStyle: 'solid', paddingBottom: '5px', marginBottom: '10px', borderColor: '#b3b3b3', display: 'flex' }}>
                                <div style={{ flex: '1' }}>
                                    <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                        <Grid item>
                                            {isEditing && el.status !== "owner_deleted" ? (
                                                <>
                                                    <TextField type="number" onChange={e => handleChangeDish(el.id, {
                                                        amount: (e.target.value.includes('-') || e.target.value.includes('0')) ? 1 : Number(e.target.value)
                                                    })} style={{ width: '100px', marginBottom: '10px' }} value={el.amount} />
                                                    <div style={{ fontWeight: 'bold' }}>{el.name}</div>
                                                </>
                                            ) : <>x{el.status === 'owner_deleted' ? 0 : el.amount} <span style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{el.name}</span></>}
                                        </Grid>
                                    </Grid>

                                    {el.variant && (
                                        <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                            <Grid item>
                                                {isEditing && el.status !== "owner_deleted" ? (
                                                    <>
                                                        <TextField onChange={e => handleChangeDish(el.id, {
                                                            variant: e.target.value,
                                                        })} value={el.variant} />
                                                    </>
                                                ) : <>{formatVariants(el.variant)}</>}

                                            </Grid>
                                        </Grid>
                                    )}

                                    {el.note && (
                                        <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                            <Grid item>
                                                <Typography color={'textSecondary'} fontWeight={'bold'} padding={'5px 0'}>
                                                    Note: {el.note}
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid container spacing={2} style={el.status === 'owner_deleted' ? { textDecoration: 'line-through' } : {}}>
                                        <Grid style={{ width: '50%', textAlign: 'left', display: 'flex', gap: '6px', alignItems: 'center' }} item>
                                            {el.printed === 1 ? (
                                                <>
                                                    <CheckCircleIcon color="primary" style={{ fontSize: '14px' }} />
                                                    <Typography color='textSecondary' variant="body2" component="h2">Completato</Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <AccessTimeFilledIcon color="warning" style={{ fontSize: '14px' }} />
                                                    <Typography color='textSecondary' variant="body2" component="h2">In preparazione</Typography>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid style={{ width: '50%', textAlign: 'right' }} item>
                                            <Typography variant='subtitle2' color='textSecondary'>
                                                {(el.amount * el.price).toFixed(2).toString().replace('.', ',')} €
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                {isEditing && (el.status === "owner_deleted" ? (
                                    <div>
                                        <IconButton onClick={() => restoreDish(tableId, el.id)}>
                                            <ReplayIcon />
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div>
                                        <IconButton onClick={() => deleteDish(tableId, el.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                ))
                                }
                            </div>

                        ))}
                        <Grid style={{ width: '100%', textAlign: 'right', marginTop: '20px' }}>
                            <Typography color='primary' fontWeight={'bold'} fontSize={18}>TOTALE: {totalPrice.toFixed(2).toString().replace('.', ',')} €</Typography>
                        </Grid>
                    </CardContent>
                    {table.accettato === 0 ? (
                        <CardActions>
                            <Button
                                style={{ marginRight: "20px" }}
                                variant="contained"
                                color="secondary"
                                onClick={() => handleCloseDialogReject(table.deliveryId)}
                            >
                                Rifiuta ordine
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCloseDialogAccept(table.deliveryId)}
                            >
                                Accetta ordine
                            </Button>

                        </CardActions>
                    ) : (
                        <CardActions>
                            {isEditing ? (
                                <Button onClick={() => handleClickEdit()} variant="contained" color="secondary">TERMINA</Button>
                            ) : (
                                <>
                                    <Button onClick={() => setIsEditing(!isEditing)} variant="contained" color="primary">MODIFICA</Button>
                                    <Button onClick={handleClickOrder} variant="contained" color="primary">ORDINA</Button>
                                </>
                            )}

                        </CardActions>
                    )}

                </Card>
            ) : (
                <div className={classes.centeredIcon}>
                    <PersonOffIcon color='primary' style={{ fontSize: '90px' }} />
                    <Typography
                        variant='subtitle1'
                        color='textSecondary'
                        style={{ textAlign: 'center', letterSpacing: '1px' }}>
                        Non ci sono ordini associati a questo tavolo al momento
                    </Typography>
                </div>
            )}
        </div >
    )
}

export default RoomViewMobile

